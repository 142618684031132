import React from "react";

/**
 * 
 * @param {{helpBpActive : string, setHelpBpActive : (arg : string) => void}} param0 
 * @returns 
 */
export const BpHelpModal = ({ helpBpActive, setHelpBpActive }) => {
  return (
    <div className={`modal-wrap modal-wrap-help blood-pressure ${helpBpActive}`}>
      <div className={`modal modal-help`}>
        <div className={`modal-help-plate`}>
          <div className={`modal-help-top`}>
            <div className={`modal-help-tit`}>고혈압</div>
            <button className={`btn modal-help-btn modal-help-btn-close`}>
              <img src="/assets/images/common/btn-modal-close-black.svg" alt="닫기" onClick={() => setHelpBpActive('')} />
            </button>
          </div>
          <div className={`modal-help-cont`}>
            <div className={`modal-help-img`}>
              <img src="/assets/images/pages/bloodPressure/bg-help-bp.png" alt="고혈압이란" />
            </div>
            <div className={`modal-help-txt`}>
              고혈압이란 위 팔에 혈압대를 감아 측정한 동맥의 압력을 기준으로 <span className={`highligh`}>수축기혈압 140 mmHg 이상</span>, 또는 <span className={`highlight`}>이완기혈압(확장기혈압) 90 mmHg 이상</span>인 경우를 말합니다. 수축기혈압과 이완기혈압 모두 120 mmHg와 80 mmHg 미만일 때 정상 혈압이라고 합니다.
            </div>
          </div>
          <div className={`modal-help-bottom`}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpHelpModal;