import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SafetyContext, BpLastContext, BsLastContext, OsLastContext, TvWeekContext, DashboardMainContext, MotionWeekContext, DoorWeekContext } from "./provider/main-provider";

export const MainDashBoard = ({ state = "good", dashboardActive }) => {
  const navigate = useNavigate();
  const { bpData } = useContext(BpLastContext);
  const { bsData } = useContext(BsLastContext);
  const { osData } = useContext(OsLastContext);
  const { tempData, humiData } = useContext(DashboardMainContext);
  const { tvWeekData } = useContext(TvWeekContext);
  const { motionWeekData } = useContext(MotionWeekContext);
  const { doorWeekData } = useContext(DoorWeekContext);
  const { safetyData } = useContext(SafetyContext);

  return (
    <section className={`dashboard-sect sect-states ${state} ${dashboardActive ? "active" : ""}`}>
      <div className="dashboard-states-bar"></div>
      <div className="dashboard-states-btns">
        <button
          type="button"
          className={`dashboard-states-btn blood-pressure ${bpData && bpData.disabled ? "dashboard-states-btn-old" : ""} ${bpData && !bpData.msg ? bpData.figure === "정상" ? '' : 'danger' : ''} ${!bpData || (bpData && bpData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/bp")}
        >
          <div className="dashboard-states-tag">
            <div className="txt">
              {bpData && (bpData.figure ? <span>{bpData.figure}</span> : "-")}
            </div>
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">혈압</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              {bpData && bpData.diastolic && bpData.systolic && (
                <>
                  <p className="dashboard-states-cont-bold">{bpData.systolic}</p>
                  <span>/</span>
                  <p className="dashboard-states-cont-bold">{bpData.diastolic}</p>
                </>
              )}
              {bpData && bpData.msg && <p className="dashboard-states-cont-bold">{bpData.msg}</p>}
              {!bpData && <p className="dashboard-states-cont-bold">loading...</p>}
            </div>
            {bpData && bpData.diastolic && bpData.systolic && <div className="dashboard-states-unit">mmHg</div>}
          </div>
          {bpData && bpData.time && (
            <div className="dashboard-states-date">{bpData.time}</div>
          )}
        </button>

        <button
          type="button"
          className={`dashboard-states-btn blood-sugar ${bsData && bsData.disabled ? "dashboard-states-btn-old" : ""} ${bsData && !bsData.msg ? bsData.figure === "정상" ? '' : 'danger' : ''} ${!bsData || (bsData && bsData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/bs")}>
          <div className="dashboard-states-tag">
            <div className="txt">
              {bsData && (!bsData.msg ? <span>{bsData.figure}</span> : "-")}
            </div>
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">혈당</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              {bsData && bsData.bld_sugar && <p className="dashboard-states-cont-bold">{bsData.bld_sugar}</p>}
              {bsData && bsData.msg && <p className="dashboard-states-cont-bold">{bsData.msg}</p>}
              {!bsData && <p className="dashboard-states-cont-bold">loading...</p>}
            </div>
            {bsData && bsData.bld_sugar && <div className="dashboard-states-unit">mg / dl</div>}
          </div>
          {bsData && bsData.time && (
            <div className="dashboard-states-date">{bsData.time}</div>
          )}
        </button>

        <button
          type="button"
          className={`dashboard-states-btn oxygen-saturation ${osData && osData.disabled ? "dashboard-states-btn-old" : ""} ${osData && !osData.msg ? osData.figure === "정상" ? '' : 'danger' : ''} ${!osData || (osData && osData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/oxy")}
        >
          <div className="dashboard-states-tag">
            <div className="txt">
              {osData && (!osData.msg ? <span>{osData.figure}</span> : "-")}
            </div>
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">산소포화도</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              {osData && osData.spo2 && <p className="dashboard-states-cont-bold">{osData.spo2}</p>}
              {osData && osData.msg && <p className="dashboard-states-cont-bold">{osData.msg}</p>}
              {!osData && <p className="dashboard-states-cont-bold">loading...</p>}
            </div>
            {osData && osData.spo2 && <div className="dashboard-states-unit">%</div>}
          </div>
          {osData && osData.time && (
            <div className="dashboard-states-date">{osData.time}</div>
          )}
        </button>

        <button
          type="button"
          className={`dashboard-states-btn watching-tv ${tvWeekData && tvWeekData.disabled ? "dashboard-states-btn-old" : ""} ${!tvWeekData || (tvWeekData && tvWeekData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/tv")}
        >
          <div className="dashboard-states-tag">
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">TV시청</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              {tvWeekData && tvWeekData.hour != null && <p className="dashboard-states-cont-bold">{tvWeekData.hour}</p>}
              {tvWeekData && tvWeekData.msg && <p className="dashboard-states-cont-bold">{tvWeekData.msg}</p>}
              {!tvWeekData && <p className="dashboard-states-cont-bold">loading...</p>}
            </div>
            {tvWeekData && tvWeekData.hour != null && <div className="dashboard-states-unit">hour</div>}
          </div>
          {tvWeekData && tvWeekData.start && tvWeekData.end && (
            <div className="dashboard-states-date">
              {tvWeekData.start} ~ {tvWeekData.end}
            </div>
          )}
        </button>

        <button
          type="button"
          className={`dashboard-states-btn room-temperature ${tempData && tempData.disabled ? "dashboard-states-btn-old" : ""} ${tempData && !tempData.msg ? tempData.figure === "보통" ? '' : 'danger' : ''} ${!tempData || (tempData && tempData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/temp")}
        >
          <div className="dashboard-states-tag">
            <div className="txt">
              {tempData ? tempData.figure : ''}
            </div>
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">방온도</div>
          <div className="dashboard-states-conts">
            <div className="dashboard-states-cont">
              <div className="dashboard-states-cont-bolds">
                {tempData && <p className="dashboard-states-cont-bold">{tempData.value.toFixed(1)}</p>}
                {!tempData && <p className="dashboard-states-cont-bold">loading...</p>}
              </div>
              {tempData && <div className="dashboard-states-unit">℃</div>}
            </div>
            {humiData && (
              <div className="dashboard-states-cont">
                <div className="dashboard-states-cont-bolds">
                  <p className="dashboard-states-cont-bold">
                    {humiData.value.toFixed(1)}
                  </p>
                </div>
                <div className="dashboard-states-unit">%</div>
              </div>
            )}
          </div>
          {tempData && tempData.time && (
            <div className="dashboard-states-date">{tempData.time}</div>
          )}
        </button>

        <button
          type="button"
          className={`dashboard-states-btn indoor-activities  ${motionWeekData && motionWeekData.disabled ? "dashboard-states-btn-old" : ""} ${!motionWeekData || (motionWeekData && motionWeekData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/motion")}
        >
          <div className="dashboard-states-tag">
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">실내활동</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              {motionWeekData && motionWeekData.hour != null && <p className="dashboard-states-cont-bold">{motionWeekData.hour}</p>}
              {motionWeekData && motionWeekData.msg && <p className="dashboard-states-cont-bold">{motionWeekData.msg}</p>}
              {!motionWeekData && <p className="dashboard-states-cont-bold">loading...</p>}
            </div>
            {motionWeekData && motionWeekData.hour != null && <div className="dashboard-states-unit">hour</div>}
          </div>
          {motionWeekData && motionWeekData.start && motionWeekData.end && (
            <div className="dashboard-states-date">
              {motionWeekData.start} ~ {motionWeekData.end}
            </div>
          )}
        </button>

        <button
          type="button"
          className={`dashboard-states-btn activity-information ${doorWeekData && doorWeekData.disabled ? "dashboard-states-btn-old" : ""} ${!doorWeekData || (doorWeekData && doorWeekData.msg) ? 'nodata' : ''}`}
          onClick={() => navigate("/main/door")}
        >
          <div className="dashboard-states-tag">
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">활동정보</div>
          <div className="dashboard-states-conts">
            <div className="dashboard-states-cont">
              <div className="dashboard-states-cont-bolds">
                {doorWeekData && doorWeekData.hour != null && <p className="dashboard-states-cont-bold">{`문 ${doorWeekData.hour}`}</p>}
                {doorWeekData && doorWeekData.msg && <p className="dashboard-states-cont-bold">{doorWeekData.msg}</p>}
                {!doorWeekData && <p className="dashboard-states-cont-bold">loading...</p>}
              </div>
              {doorWeekData && doorWeekData.hour != null && <div className="dashboard-states-unit">hour</div>}
            </div>
          </div>
          <div className="dashboard-states-date">
            {doorWeekData &&
              doorWeekData.start &&
              doorWeekData.end &&
              `${doorWeekData.start} ~ ${doorWeekData.end}`}
          </div>
        </button>

        <button
          type="button"
          className={`dashboard-states-btn safety ${safetyData && safetyData.disabled ? "dashboard-states-btn-old nodata" : ""} ${!safetyData ? "nodata" : ''}`}
          onClick={() => { if (safetyData && !safetyData.disabled) navigate("/main/safe") }}>
          <div className={`dashboard-states-tag ${safetyData && safetyData.dangerous != null ? (safetyData.dangerous ? "danger" : "normal") : ''}`}>
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">안전화재</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              <p className="dashboard-states-cont-bold">
                {(safetyData &&
                  safetyData.dangerous != null ? (
                  safetyData.dangerous
                    ? "위험"
                    : "정상") : (safetyData && safetyData.disabled ? "NODATA" : '')) ||
                  (!safetyData && "loading...")}
                {safetyData && safetyData.msg && safetyData.msg}
              </p>
            </div>
          </div>
          <div className="dashboard-states-icon-safety"></div>
        </button>

        <button
          type="button"
          className="dashboard-states-btn together"
          onClick={() => navigate("/main/check")}
        >
          <div className="dashboard-states-tag">
            <div className="icon"></div>
          </div>
          <div className="dashboard-states-cate">마을 건강</div>
          <div className="dashboard-states-cont">
            <div className="dashboard-states-cont-bolds">
              <p className="dashboard-states-cont-bold">함께</p>
            </div>
          </div>
        </button>
      </div>
    </section>
  );
};

export default MainDashBoard;
