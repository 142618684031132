import React, { memo } from "react"
import { Link } from "react-router-dom";
import Logout from "../../logout"

/**
 * 
 * @param {{page : string, pagetxt : string}} param0 
 * @returns 
 */
const _MngrHeader = ({ page = 'mngr', pagetxt }) => {
  return (
    <header className={`header header-${page}`}>
      <Link to="/mngr" className={`logo header-logo logo-header-${page}`}>
        <div className={`icon`} area-label='로고'></div>
        <div className={`txt`}>
          <img src="/assets/images/common/logo-txt-white.svg" alt="독거가구 응급안전 안심 서비스" />
        </div>
      </Link>
      <div className={`pagename header-pagename-${page}`}>{pagetxt}</div>
      <button type="button" className={`btn-logout btn-logout-header-${page}`}>
        <span className={`icon`}></span>
        <span className={`txt`}><Logout /></span>
      </button>
    </header>
  )
};

export const MngrHeader = memo(_MngrHeader);
export default MngrHeader;