import React from "react";
import RangeModal, { openModalRange } from "../../modal/range-modal";
import MngrHeader from "./mngr-header";

/**
 * 
 * @param {{
* page : string, 
* pagetxt : string, 
* mode : string
* rangeSaveAction : ((start_date : string, end_date : string) => void) | undefined, 
* }} param0 
* @returns 
*/
export const MngrDetailHeader = ({ page, pagetxt, mode = "range", rangeSaveAction }) => {
  return (
    <>
      <RangeModal saveAction={rangeSaveAction} mode={mode} />
      <MngrHeader page={page} pagetxt={pagetxt} />
    </>
  )
};

export default MngrDetailHeader;
export { openModalRange };