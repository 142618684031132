import React, { useState } from 'react';

export const DashboardActiveContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element}} param0 
 * @returns 
 */
export const DashboardActiveProvider = ({ children}) => {
    const [dashboardActive, setDashboardActive] = useState(false);

  return (
    <DashboardActiveContext.Provider value={{ dashboardActive, setDashboardActive }}>
      {children}
    </DashboardActiveContext.Provider>
  )
}