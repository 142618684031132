import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';

const motionLastUrl = {
  true: '/api/v1/mngr/activity/motion/last',
  false:'/api/v1/user/activity/motion/last',
}

export const MotionLastContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean, userId : number | null}} param0 
 * @returns 
 */
export const MotionLastProvider = ({ children, allow_url, isMngr = false, userId = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [motionLastData, setMotionLastData] = useState({});

  useEffect(() => {
    if(isMngr && !userId){
      return;
    }
    
    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      const requset = () => {
        getAxiosInterceptors(navigate)
          .get(motionLastUrl[isMngr], isMngr && userId != null ? {params : {user : userId}} : undefined)
          .then((response) => {
            if (response.data.msg === "success") {
              setMotionLastData(response.data.data);
            }
          });
      };

      requset();
      const interval = setInterval(() => {
        requset();
      }, 50000);

      return () => clearInterval(interval);
    }

  }, [location.pathname]);

  return (
    <MotionLastContext.Provider value={{ motionLastData, setMotionLastData }}>
      {children}
    </MotionLastContext.Provider>
  )
}