import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login.js';
import SignUp from './pages/sign-up.js';
import Sos from './pages/sos.js'
import Device from './pages//device/device';

import Main from './pages/main/main.js'
import MainTemp from './pages//main/main-temp';
import MainTv from './pages/main/main-tv';
import MainBP from './pages/main/main-bp';
import MainBS from './pages/main/main-bs';
import MainOxy from './pages/main/main-oxy';
import MainDoor from './pages/main/main-door';
import MainMotion from './pages/main/main-motion';
import MainSafe from './pages/main/main-safe';
import MainCheck from './pages/main/main-check';

import Mngr from './pages/mngr/mngr';
import MngrHealth from './pages/mngr/mngr-health.js';
import MngrActivity from './pages/mngr/mngr-activity.js';
import MngrSafety from './pages/mngr/mngr-safety.js';

import { PrivateRoute } from './components/route/private-route.js';
import { AutoDashBoardRoute } from './components/route/auto-dashboard-route.js';

import { MngrProvider } from './components/provider/mngr-provider.js';
import { MainProvider } from './components/provider/main-provider.js';

import { window_resize } from './utils/assets-manager.js'

const App = () => {
  useEffect(() => {
    const remove = window_resize();

    return () => remove();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' exact element={<AutoDashBoardRoute><Login /></AutoDashBoardRoute>} />
        <Route path='/' exact element={<AutoDashBoardRoute><Login /></AutoDashBoardRoute>} />
        <Route path='/sos' element={<Sos />} />
        <Route path='/login' exact element={<AutoDashBoardRoute><Login /></AutoDashBoardRoute>} />
        <Route path='/signup' exact element={<AutoDashBoardRoute><SignUp /></AutoDashBoardRoute>} />
        <Route path='/device' exact element={<PrivateRoute><Device /></PrivateRoute>} />

        <Route path='/main/*' exact element={
          <PrivateRoute>
            <MainProvider>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/temp" element={<MainTemp />} />
                <Route path="/tv" element={<MainTv />} />
                <Route path="/bp" element={<MainBP />} />
                <Route path="/bs" element={<MainBS />} />
                <Route path="/oxy" element={<MainOxy />} />
                <Route path="/door" element={<MainDoor />} />
                <Route path="/motion" element={<MainMotion />} />
                <Route path="/safe" element={<MainSafe />} />
                <Route path="/check" element={<MainCheck />} />
              </Routes>
            </MainProvider>
          </PrivateRoute>}
        />

        <Route path='/mngr/*' exact element={
          <PrivateRoute isAdmin={true}>
            <MngrProvider>
              <Routes>
                <Route path="/" element={<Mngr />} />
                <Route path="/health" element={<MngrHealth />} />
                <Route path="/activity" element={<MngrActivity />} />
                <Route path="/safety" element={<MngrSafety />} />
              </Routes>
            </MngrProvider>
          </PrivateRoute>
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
