import React from "react";
import RecordModal, { openModalRecord } from "../../modal/record-modal";
import MainDetailHeader, { openModalRange } from "./main-detail-header";

/**
 * 
 * @param {{
* page : string, 
* pagename : string | undefined, 
* pagetxt : string, 
* state : string, 
* safety : string, 
* rangeSaveAction : ((start_date : string, end_date : string) => void) | undefined, 
* recordSaveAction : ((value : {input1:number, input2:number|undefined}, date : string) => void) | undefined
* }} param0 
* @returns 
*/
export const MainHealthHeader = ({ page, pagename, pagetxt, state = 'good', safety = 'normal', rangeSaveAction, recordSaveAction }) => {
  return (
    <>
      <RecordModal pagename={pagename} saveAction={recordSaveAction} />
      <MainDetailHeader page={page} pagetxt={pagetxt} state={state} safety={safety} rangeSaveAction={rangeSaveAction} />
    </>
  );
}

export default MainHealthHeader;
export { openModalRange, openModalRecord };