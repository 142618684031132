import React from "react";
import NumCountAnimate from "../animate";

/**
 * 
 * @param {{osData : {time : string,disabled : boolean, spo2 : number, bpm : number, figure : string,detail_figure : string }, setHelpOsActive : (arg : string) => void}} param0 
 * @returns 
 */
export const OsSectCurrent = ({ osData, setHelpOsActive }) => {
  return (
    <section className={`os-sect sect-current`}>
      <div className={`os-current-top`}>
        <h3 className={`sect-tit os-current-tit`}>최근 산소포화도</h3>
        <div className={`os-current-date`}>
          <div className={`icon`}></div>
          <div className={`txt`}>{osData && osData.time ? osData.time : ''}</div>
        </div>
      </div>
      <div className={`os-current-cont`}>
        <div className={`os-current-nums`}>
          <div className={`os-current-num`}>{osData && osData.spo2 && <NumCountAnimate targetNum={osData.spo2} />}</div>
        </div>
        <div className={`os-current-result ${osData && osData.figure === "정상" ? "normal" : "danger"}`}>
          <div className={`txt`}>{osData && osData.detail_figure ? osData.detail_figure : ''}</div>
          <div className={`icon`}></div>
        </div>
        <div className={`os-current-standard`} style={{ cursor: "pointer" }} onClick={() => setHelpOsActive('active oxygen-saturation')}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
            <defs>
              <filter id="타원_1215" x="0" y="0" width="22" height="22" filterUnits="userSpaceOnUse">
                <feOffset dy="1" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="1" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g id="그룹_18566" data-name="그룹 18566" transform="translate(-162 -385)">
              <g transform="matrix(1, 0, 0, 1, 162, 385)" filter="url(#타원_1215)">
                <g id="타원_1215-2" data-name="타원 1215" transform="translate(3 2)" fill="#fff" stroke="#000" strokeWidth="1">
                  <circle cx="8" cy="8" r="8" stroke="none" />
                  <circle cx="8" cy="8" r="7.5" fill="none" />
                </g>
              </g>
              <path id="패스_142299" data-name="패스 142299" d="M2.838.22A.633.633,0,0,1,2.371.038a.633.633,0,0,1-.181-.468A.6.6,0,0,1,2.371-.88a.644.644,0,0,1,.468-.176.617.617,0,0,1,.456.17.617.617,0,0,1,.171.457.644.644,0,0,1-.176.468A.6.6,0,0,1,2.838.22ZM.5-6.512a2.758,2.758,0,0,1,1-1.309,2.55,2.55,0,0,1,1.518-.506,2.809,2.809,0,0,1,1.3.292,2.125,2.125,0,0,1,.88.825,2.375,2.375,0,0,1,.314,1.227A2.423,2.423,0,0,1,5.17-4.763,4.179,4.179,0,0,1,4.048-3.575a1.694,1.694,0,0,0-.567.6,2.154,2.154,0,0,0-.127.908L2.3-2.057a3.123,3.123,0,0,1,.248-1.38,2.719,2.719,0,0,1,.863-1,3.275,3.275,0,0,0,.83-.792,1.4,1.4,0,0,0,.215-.759,1.186,1.186,0,0,0-.4-.93,1.5,1.5,0,0,0-1.039-.357,1.5,1.5,0,0,0-.908.313,1.833,1.833,0,0,0-.622.809Z" transform="translate(170 399)" />
            </g>
          </svg>

          <div className={`txt`}>정상 95이상</div>
        </div>
      </div>
    </section>
  );
};

export default OsSectCurrent;