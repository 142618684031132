import React, { useEffect, useState, useContext } from "react";

import Swiper from "swiper";

import { getWeekOnlyYMD, getTodayOnlyYMD } from "../../utils/date";

import { MngrDetailHeader, openModalRange } from "../../components/header/mngr/mngr-detail-header";
import { MngrContext, TvLastContext, MotionLastContext, DoorLastContext, DashboardMainContext } from "../../components/provider/mngr-provider";
import { ActivitySectCurrent, ActivitySectChartTime, ActivitySectChartDate, RtSwiper } from "../../components/activity";
import Bottom from "../../components/bottom";

const page = 'mngr';
const pagename = 'mngractivities';
const pagetxt = '활동정보';

const MngrActivity = () => {
	const { data } = useContext(MngrContext);

	const [dateSingle, setDateSingle] = useState(getTodayOnlyYMD());
	const [dateRange, setDateRange] = useState(getWeekOnlyYMD());

	const { tvLastData } = useContext(TvLastContext);
	const { motionLastData } = useContext(MotionLastContext);
	const { doorLastData } = useContext(DoorLastContext);
	const { tempData, humiData } = useContext(DashboardMainContext);


	const [tempTab, setTempTab] = useState('temp');
	const [calTempState, setCalTempState] = useState('time');
	const [calHumiState, setCalHumiState] = useState('time');
	const [calDoorState, setCalDoorState] = useState('time');
	const [calMotionState, setCalMotionState] = useState('time');
	const [rangeMode, setRangeMode] = useState('single');

	const [tabActive, setTabActive] = useState('wt');

	const [swiper, setSwiper] = useState(null);


	useEffect(() => {
		const swiperMngr = new Swiper('.swiper.swiper-mngractivities', {
			slidesPerView: "auto",
			spaceBetween: 60,
			preventClicks: false,
			allowTouchMove: false,
		});

		setSwiper(swiperMngr);

		return () => {
			swiperMngr.destroy(true, true);
			setSwiper(null);
		};
	}, []);

	useEffect(() => {
		if (tabActive === "rt") {
			if ((tempTab === 'temp' && calTempState === 'time') || (tempTab === 'humi' && calHumiState === 'time'))
				setDateSingle(new Date(dateSingle));
			else if ((tempTab === 'temp' && calTempState === 'date') || (tempTab === 'humi' && calHumiState === 'date'))
				setDateRange({ ...dateRange });
		}
		else if (tabActive === "ai") {
			if (calDoorState === 'time')
				setDateSingle(new Date(dateSingle));
			else if (calDoorState === 'date')
				setDateRange({ ...dateRange });
		}
		else if (tabActive === "ia") {
			if (calMotionState === 'time')
				setDateSingle(new Date(dateSingle));
			else if (calMotionState === 'date')
				setDateRange({ ...dateRange });
		}
	}, [tempTab, calTempState, calDoorState, calMotionState]);

	const changeDateRange = (sd, ed) => {
		if (tabActive === 'wt') {
			setDateRange({ start: sd, end: ed });
		}
		else if (tabActive === "rt") {
			if ((tempTab === 'temp' && calTempState === 'time') || (tempTab === 'humi' && calHumiState === 'time'))
				setDateSingle(sd);
			else if ((tempTab === 'temp' && calTempState === 'date') || (tempTab === 'humi' && calHumiState === 'date'))
				setDateRange({ start: sd, end: ed });
		}
		else if (tabActive === "ai") {
			if (calDoorState === 'time')
				setDateSingle(sd);
			else if (calDoorState === 'date')
				setDateRange({ start: sd, end: ed });
		}
		else if (tabActive === "ia") {
			if (calMotionState === 'time')
				setDateSingle(sd);
			else if (calMotionState === 'date')
				setDateRange({ start: sd, end: ed });
		}
	}


	return (
		<div className={`wrap wrap-desktop`}>
			<div className={`layout-desktop layout-${page}`}>

				<div className={`wrap-${pagename}`}>

					<MngrDetailHeader page={page} pagetxt={pagetxt + " - " + data.name} mode={rangeMode} rangeSaveAction={changeDateRange} />

					<div className={`${pagename}-tabs`}>
						<button type="button" className={`${pagename}-tab wt ${tabActive === "wt" ? "active" : ""}`} onClick={() => { setTabActive('wt'); swiper.slideTo(0, 1000) }}>TV</button>
						<button type="button" className={`${pagename}-tab rt ${tabActive === "rt" ? "active" : ""}`} onClick={() => { setTabActive('rt'); swiper.slideTo(1, 1000) }}>온습도</button>
						<button type="button" className={`${pagename}-tab ai ${tabActive === "ai" ? "active" : ""}`} onClick={() => { setTabActive('ai'); swiper.slideTo(2, 1000) }}>활동정보</button>
						<button type="button" className={`${pagename}-tab ia ${tabActive === "ia" ? "active" : ""}`} onClick={() => { setTabActive('ia'); swiper.slideTo(3, 1000) }}>실내활동</button>
					</div>
					<div className={`${pagename}-containers swiper swiper-${pagename}`}>
						<div className={`swiper-wrapper`}>

							<div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "wt" ? "active" : ""}`} onClick={() => { setTabActive('wt'); swiper.slideTo(0, 1000) }}>
								<div className={`${pagename}-container ${pagename}-container-wt`}>

									<ActivitySectCurrent pagename="wt" subpagename="tv" onoffText={{ on: "켜짐", off: "꺼짐" }} sensorLastData={tvLastData} />
									<section className={`wt-sect sect-chart`}>
										<div className={`wt-chart-top`}>
											<h3 className={`sect-tit wt-chart-tit`}>기간별 {pagetxt} 추이</h3>
											<button type="button" className={`btn btn-range-date wt-chart-btn-date`} onClick={() => { setRangeMode('range'); openModalRange() }}>
												기간설정
											</button>
										</div>
										<div className={`wt-chart-cont`}>
											<ActivitySectChartDate pagename="wt" url="/api/v1/mngr/activity/tv/range" sensorLastData={tvLastData} dateRange={dateRange} color="#48847D" userId={data.user_id} />
										</div>
									</section>

								</div>
							</div>

							<div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "rt" ? "active" : ""}`} onClick={() => { setTabActive('rt'); swiper.slideTo(1, 1000) }}>
								<div className={`${pagename}-container ${pagename}-container-rt`}>

									<RtSwiper setRangeMode={setRangeMode} openModalRange={openModalRange} isMngr={true} userId={data.user_id}
										temp={tempData && tempData.value ? parseInt(tempData.value) : 0} humi={humiData && humiData.value ? parseInt(tempData.value) : 0}
										dateSingle={dateSingle} setDateSingle={setDateSingle}
										dateRange={dateRange} setDateRange={setDateRange}
										tab={tempTab} setTab={setTempTab}
										calTempState={calTempState} setCalTempState={setCalTempState}
										calHumiState={calHumiState} setCalHumiState={setCalHumiState} />

								</div>
							</div>

							<div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "ai" ? "active" : ""}`} onClick={() => { setTabActive('ai'); swiper.slideTo(2, 1000) }}>
								<div className={`${pagename}-container ${pagename}-container-ai`}>
									<ActivitySectCurrent pagename="ai" subpagename="door" sensorLastData={doorLastData} onoffText={{ on: "열림", off: "닫힘" }} isUsingSubnameInSwiper={true} />

									<section className={`ai-sect sect-chart`}>
										<div className={`ai-chart-door-wrap active`}>
											<div className={`ai-chart-top`}>
												<h3 className={`sect-tit ai-chart-tit`}>문 열림 추이</h3>
												<div className={`ai-chart-tabs ai-chart-door-tabs`}>
													<div className={`ai-chart-tab ai-chart-door-tab ${calDoorState === 'time' ? 'active' : ''}`} onClick={() => setCalDoorState('time')}>시간별</div>
													<div className={`ai-chart-tab ai-chart-door-tab ${calDoorState === 'date' ? 'active' : ''}`} onClick={() => setCalDoorState('date')}>날짜별</div>
												</div>
												<button type="button" className={`btn btn-range-date ai-chart-btn-date`} onClick={() => { setRangeMode(calDoorState === 'time' ? 'single' : 'range'); openModalRange() }}>
													기간설정
												</button>
											</div>
											<div className={`ai-chart-cont`}>
												<div className={`ai-chart-graphs-door-time ${calDoorState === 'time' ? 'active' : ''}`}>
													<ActivitySectChartTime pagename="ai" url="/api/v1/mngr/activity/door/day" sensorLastData={doorLastData} date={dateSingle} color="#8667C4" userId={data.user_id} />
												</div>
												<div className={`ai-chart-graphs-door-date ${calDoorState === 'date' ? 'active' : ''}`}>
													<ActivitySectChartDate pagename="ai" url="/api/v1/mngr/activity/door/range" sensorLastData={doorLastData} dateRange={dateRange} color="#8667C4" userId={data.user_id} />
												</div>
											</div>
										</div>
									</section>
								</div>
							</div>

							<div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "ia" ? "active" : ""}`} onClick={() => { setTabActive('ia'); swiper.slideTo(3, 1000) }}>
								<div className={`${pagename}-container ${pagename}-container-ia`}>

									<ActivitySectCurrent pagename="ia" subpagename="active"
										sensorLastData={motionLastData} onoffText={{ on: "활동중", off: "없음" }}
										isUsingSubnameInSwiper={true} isUsingSubnameInSlide={false} />

									<section className={`ia-sect sect-chart`}>
										<div className={`ia-chart-wrap active`}>
											<div className={`ia-chart-top`}>
												<h3 className={`sect-tit ia-chart-tit`}>실내활동 추이</h3>
												<div className={`ia-chart-tabs ia-chart-tabs`}>
													<div className={`ia-chart-tab ia-chart-tab ${calMotionState === 'time' ? 'active' : ''}`} onClick={() => setCalMotionState('time')}>시간별</div>
													<div className={`ia-chart-tab ia-chart-tab ${calMotionState === 'date' ? 'active' : ''}`} onClick={() => setCalMotionState('date')}>날짜별</div>
												</div>
												<button type="button" className={`btn btn-range-date ia-chart-btn-date`} onClick={() => { setRangeMode(calDoorState === 'time' ? 'single' : 'range'); openModalRange() }}>
													기간설정
												</button>
											</div>
											<div className={`ia-chart-cont`}>
												<div className={`ia-chart-graphs-time ${calMotionState === 'time' ? 'active' : ''}`}>
													<ActivitySectChartTime pagename="ia" url="/api/v1/mngr/activity/motion/day" sensorLastData={motionLastData} date={dateSingle} color="#92ACEB" userId={data.user_id} />
												</div>
												<div className={`ia-chart-graphs-date ${calMotionState === 'date' ? 'active' : ''}`}>
													<ActivitySectChartDate pagename="ia" url="/api/v1/mngr/activity/motion/range" sensorLastData={motionLastData} dateRange={dateRange} color="#92ACEB" userId={data.user_id} />
												</div>
											</div>

										</div>
									</section>

								</div>
							</div>

						</div>

					</div>

					<Bottom pagename={pagename} isMngr={true} isWriteBtnShow={false} />

				</div>

			</div>
		</div>
	)
}

export default MngrActivity;