import Swal from "sweetalert2";

const defaultStyle = {
  confirmButtonColor: "#18CC9D",
  confirmButtonText: "확인"
}

const defaultFintSize = "15px"

export const successAlert = (msg) => {
  Swal.fire({ icon: "success", title: msg, ...defaultStyle });
  const btn = Swal.getConfirmButton();
  if (btn)
    btn.style.fontSize = defaultFintSize
}

export const warningAlert = (msg) => {
  Swal.fire({ icon: "warning", title: msg, ...defaultStyle });
  const btn = Swal.getConfirmButton();
  if (btn)
    btn.style.fontSize = defaultFintSize
}

export const errorAlert = (msg) => {
  Swal.fire({ icon: "error", title: msg, ...defaultStyle })
  const btn = Swal.getConfirmButton();
  if (btn)
    btn.style.fontSize = defaultFintSize
}

export const infoAlert = (msg) => {
  Swal.fire({ icon: "info", title: msg, ...defaultStyle })
  const btn = Swal.getConfirmButton();
  if (btn)
    btn.style.fontSize = defaultFintSize
}

export default successAlert;