import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';

export const StateContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element}} param0 
 * @returns 
 */
export const StateProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState('good');

  useEffect(() => {
    getAxiosInterceptors(navigate)
      .get('/api/v1/user/health/state')
      .then((response) => {
        if (response.data.msg === "success") {
          setState(response.data.state);
        }
      });
  }, [location.pathname]);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  )
}