import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';
import { getDateToYMDOrMD } from '../../utils/date';

const bpLastUrl = {
  true: '/api/v1/mngr/health/bp/last',
  false: '/api/v1/user/health/bp/last',
}

export const BpLastContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean, userId : number | null}} param0 
 * @returns 
 */
export const BpLastProvider = ({ children, allow_url, isMngr = false, userId = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [bpData, setBpData] = useState(null);
  const [flag, setFlag] = useState(false);
  const refresh = () => setFlag(!flag);

  useEffect(() => {
    if(isMngr && !userId){
      return;
    }

    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      getAxiosInterceptors(navigate)
        .get(bpLastUrl[isMngr], isMngr && userId != null ? {params : {user : userId}} : undefined)
        .then((response) => {
          if (response.data.msg === 'success') {
            const value = response.data.data;

            const threeMonthAgo = new Date();
            threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
            const time = new Date(value.time);

            const data = {
              time: getDateToYMDOrMD(time),
              disabled: time < threeMonthAgo,
              systolic: value.systolic,
              diastolic: value.diastolic,
              figure: value.figure ? value.figure.length > 4 ? "위험" : value.figure === "정상" ? "정상" : "주의" : "",
              detail_figure: value.figure,
            }
            setBpData(data);
          }
          else setBpData({ msg: "NO DATA" });
        });
    }

  }, [location.pathname, flag]);

  return (
    <BpLastContext.Provider value={{ bpData, setBpData, refresh }}>
      {children}
    </BpLastContext.Provider>
  )
}