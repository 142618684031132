import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { openSosModal } from "./modal/sos-modal";

const _Bottom = ({ pagename, writeOnClick, isWriteBtnShow = true, isMngr = false }) => {
  const navigate = useNavigate();
  return (
    <section className={`bottom ${isMngr ? isWriteBtnShow ? "mngr-bottom" : "mngr" : "emergency"} ${pagename}`}>
      {isWriteBtnShow && 
      <button type="button" className="btn btn-write" onClick={() => writeOnClick && writeOnClick()}>
        <img src="/assets/images/common/icon-write-white.svg" alt="기록추가" />
      </button>}
      {!isMngr &&
        <button type="button" className="btn btn-emergency" onClick={() => openSosModal()}>
          <div className="icon"><img src="/assets/images/common/icon-emergency-white.svg" alt="응급" /></div>
          <div className="txt">응급호출</div>
        </button>}
      {!isWriteBtnShow && isMngr &&
        <button type="button" className="btn btn-list" onClick={() => navigate('/mngr')}>
          <div className="txt">목록</div>
        </button>}
    </section>
  );
};

export const Bottom = memo(_Bottom);
export default Bottom;