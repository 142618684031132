import React, { useEffect, memo } from "react";
import getSmsUrl from '../../utils/sms-linking';

const _SosModal = () => {
  useEffect(() =>{
    return () => window.localStorage.removeItem('sos-control');
  }, [])

  const stopCountdown = (html, body) => {
    const modalEmergencyCount = document.querySelector('.modal-emergency-count');
    const sosControl = window.localStorage.getItem('sos-control');
    let [countdown, hasHtmlSeal, hasBodySeal] = sosControl ? sosControl.split(", ") : [null, false, false];

    if (countdown != null)
      clearInterval(parseInt(countdown));

    if (hasHtmlSeal && html)
      html.classList.add('seal');

    if (hasBodySeal && body)
      body.classList.add('seal');

    modalEmergencyCount.textContent = '5';

    window.localStorage.removeItem('sos-control');
  }

  const closeHandle = () => {
    const modalWrapEmergency = document.querySelector('.modal-wrap.modal-wrap-emergency');
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    modalWrapEmergency.classList.remove('active');
    html.classList.remove('seal');
    body.classList.remove('seal');

    stopCountdown(html, body);
  }

  const SosReport = (msg) => {
    const uri = getSmsUrl('119', msg);
    if (uri) {
      window.open(uri);
    }
    else {
      alert("모바일 환경에서 시도해 주십시오.");
    }
    closeHandle();
  }

  return (
    <div className={`modal-wrap modal-wrap-emergency`}>
      <div className="modal modal-emergency">
        <div className="modal-emergency-count">5</div>
        <div className="modal-emergency-notice">
          5초 동안 무반응시 <span className="modal-emergency-notice-strong">자동으로 119</span> 문자를 발송합니다.
        </div>
        <div className="modal-emergency-btns">
          <button className="btn modal-emergency-btn modal-emergency-btn-robber" onClick={() => SosReport("집에 강도가 들었습니다.")}>
            <img src="/assets/images/common/btn-robber.png" alt="강도" />
          </button>
          <button className="btn modal-emergency-btn modal-emergency-btn-dyspnoea" onClick={() => SosReport("호흡에 문제가 있습니다.")}>
            <img src="/assets/images/common/btn-dyspnoea.png" alt="호흡곤란" />
          </button>
          <button className="btn modal-emergency-btn modal-emergency-btn-pain" onClick={() => SosReport("가슴이 아픕니다.")}>
            <img src="/assets/images/common/btn-pain.png" alt="가슴통증" />
          </button>
          <button className="btn modal-emergency-btn modal-emergency-btn-falldown" onClick={() => SosReport("넘어져서 움직일 수가 없습니다.")}>
            <img src="/assets/images/common/btn-falldown.png" alt="넘어짐" />
          </button>
        </div>
        <button className="btn modal-emergency-btn modal-emergency-btn-close" onClick={() => { closeHandle() }}>
          <img src="/assets/images/common/btn-modal-emergency-close-white.svg" alt="닫기" />
        </button>
      </div>
    </div>
  );
}

export const SosModal = memo(_SosModal);
export default SosModal;

const startCountdown = (modalWrapEmergency) => {
  const modalEmergencyCount = document.querySelector('.modal-emergency-count');
  const html = document.querySelector('html');
  const body = document.querySelector('body');
  const hasHtmlSeal = html ? html.classList.contains("seal") : false;
  const hasBodySeal = body ? body.classList.contains("seal") : false;
  if (modalEmergencyCount) {
    let count = 5;
    modalEmergencyCount.textContent = count;

    const countdown = setInterval(() => {
      modalEmergencyCount.textContent = --count;

      if (count <= 0) {
        clearInterval(countdown);
        const uri = getSmsUrl('119', "위급한 상황입니다.");
        if (uri) {
          window.open(uri);
        }
        else {
          alert("모바일 환경에서 시도해 주십시오.");
        }
        if (modalWrapEmergency.classList.contains('active')) {
          modalWrapEmergency.classList.remove('active');
        }
      }
    }, 1000);

    window.localStorage.setItem('sos-control', `${countdown}, ${hasHtmlSeal}, ${hasBodySeal}`);
  }
}

const openSosModal = () => {
  const modalWrapEmergency = document.querySelector('.modal-wrap.modal-wrap-emergency');
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  if (modalWrapEmergency)
    modalWrapEmergency.classList.add('active');
  if (html)
    html.classList.add('seal');
  if (body)
    body.classList.add('seal');

  startCountdown(modalWrapEmergency);
}

export { openSosModal };