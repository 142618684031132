import React, { useContext, useState, useCallback } from "react";
import { MngrContext, SafetyContext } from "../../components/provider/mngr-provider";

import MngrSafetyHeader, { openModalAlarm } from "../../components/header/mngr/mngr-safety-header";
import Bottom from "../../components/bottom";
import { SafetyCurrentBox, SafetyBtnInfo } from "../../components/safety";

const page = 'mngr';
const pagename = 'mngrsafety';
const pagetxt = '안전정보';
const url = '/api/v1/mngr/safety/list'

const MngrSafety = () => {
  const { data } = useContext(MngrContext);
  const { safetyData } = useContext(SafetyContext);
  const [devicetype, setDevicetype] = useState('');

  const customOpenModalAlarm = useCallback((type) => {
    if (type === 'fire') setDevicetype('fire');
    else if (type === 'gas') setDevicetype('gas');
    else if (type === 'smoke') setDevicetype('smoke');
    openModalAlarm();
  }, []);

  const fireOpenModalAlarm = useCallback(() => customOpenModalAlarm('fire'), []);
  const gasOpenModalAlarm = useCallback(() => customOpenModalAlarm('gas'), []);
  const smokeOpenModalAlarm = useCallback(() => customOpenModalAlarm('smoke'), []);

  return (
    <div className="wrap wrap-desktop">
      <div className={`layout-desktop layout-${page}`}>

        <div className={`wrap-${pagename}`}>

          <MngrSafetyHeader page={page} pagetxt={pagetxt + " - " + data.name} url={url} devicetype={devicetype} userId={data.user_id} />

          <section className={`${pagename}-sect sect-current`}>
            <div className="safety-current-top">
              <h3 className="sect-tit safety-current-tit">안전 센서 상태</h3>
            </div>
            <div className="safety-current-cont">
              <div className="safety-current-boxes">

                {safetyData && safetyData.fire != null &&
                  <div className="safety-current-box-wrap">
                    <SafetyCurrentBox subname={{ en: "fire", ko: "화재" }} safetyData={safetyData} />

                    <SafetyBtnInfo subname="fire" openModalAlarm={fireOpenModalAlarm} />
                  </div>}

                {safetyData && safetyData.gas != null &&
                  <div className="safety-current-box-wrap">
                    <SafetyCurrentBox subname={{ en: "gas", ko: "가스" }} safetyData={safetyData} />
                    <SafetyBtnInfo subname="gas" openModalAlarm={gasOpenModalAlarm} />
                  </div>}

                {safetyData && safetyData.smoke != null &&
                  <div className="safety-current-box-wrap">
                    <SafetyCurrentBox subname={{ en: "smoke", ko: "연기" }} safetyData={safetyData} />
                    <SafetyBtnInfo subname="smoke" openModalAlarm={smokeOpenModalAlarm} />
                  </div>}

              </div>
            </div>
          </section>

          <Bottom pagename={pagename} isMngr={true} isWriteBtnShow={false} />

        </div>

      </div>
    </div>
  )
}

export default MngrSafety;