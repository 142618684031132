import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { getDateToIsoformat, getDateToYMD } from "../../utils/date";
import flatpickr from "flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import { errorAlert } from "../../utils/alert";

const pagenames = [
  'blood-pressure',
  'blood-sugar',
  'oxygen-saturation'
];

/**
 * 
 * @param {{pagename : string, saveAction : (value : {input1:number, input2:number|undefined}, date : string) => void}} param0 
 * @returns 
 */
export const RecordModal = ({ pagename, saveAction }) => {
  const dateRecordRef = useRef();
  const recordCalendarRef = useRef();
  const [recordCalendar, setRecordCalendar] = useState(null);
  const [selectedValue, setSelectedValue] = useState({ hour: "0", minute: "0" })
  const hoursRef = useRef();
  const minutesRef = useRef();
  const [focus, setFocus] = useState('')
  const input1 = useRef(null);
  const input2 = useRef(null);

  const placeholder = useMemo(() => {
    if (pagename === "bp") return "수축기 혈압"
    else if (pagename === "bs") return "혈당"
    else if (pagename === "os") return "산소포화도"
    return ''
  }, [pagename]);

  const unit = useMemo(() => {
    if (pagename === "bp") return "mmHg"
    else if (pagename === "bs") return "mg/dl"
    else if (pagename === "os") return "%"
    return ''
  }, [pagename]);

  useEffect(() => {
    if (recordCalendarRef.current) {
      const recordCalendar = flatpickr(recordCalendarRef.current, {
        clickOpens: false,
        locale: Korean,
        mode: 'single',
        disableMobile: true,
        dateFormat: 'Y-m-d',
        defaultDate: new Date(),
        onReady: (selectedDates) => {
          dateRecordRef.current.value = formatDateTime(selectedDates[0]);
          calendarHandle();
        },
        onChange: (selectedDates) => {
          dateRecordRef.current.value = formatDateTime(selectedDates[0]);
          calendarHandle();
        },
        onClose: () => calendarHandle(),
      });

      setRecordCalendar(recordCalendar);

      return () => {
        recordCalendar.destroy();
      }
    }
  }, []);

  const calendarHandle = () => {
    const date = new Date(dateRecordRef.current.value);
    const today = new Date();
    const isToday = date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();

    if (isToday)
      setSelectedValue({ hour: today.getHours(), minute: today.getMinutes() })
    else if (date < today)
      setSelectedValue({ hour: 23, minute: 59 })
    else setSelectedValue({ hour: 0, minute: 0 })
  }

  const clickAndFocusHandle = () => {
    if (recordCalendar && recordCalendar.isEnabled()) {
      recordCalendar.open();
    }
    if (recordCalendar && recordCalendar.calendarContainer)
      recordCalendar.calendarContainer.classList.add(pagename);
  };

  const formatDateTime = useCallback((date) => {
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0);
  }, []);

  const clear = () => {
    dateRecordRef.current.value = formatDateTime(new Date());
    input1.current.value = null
    if (input2 && input2.current) input2.current.value = null

    if (recordCalendar)
      recordCalendar.setDate(formatDateTime(new Date()))

    calendarHandle();
  }

  const saveHandle = () => {
    if (!input1.current || !input1.current.value) {
      errorAlert('값을 입력해 주세요.');
      return;
    }

    if (pagename === "bp" && input2 && (!input2.current || !input2.current.value)) {
      errorAlert('값을 입력해 주세요.');
      return;
    }

    if (saveAction) {
      if (input1.current.value < 0 || (pagename === "bp" && input2.current.value < 0)) {
        errorAlert('0보다 크거나 같은 값을 입력해주세요.');
        return;
      }

      if (pagename === 'os' && input1.current.value > 100) {
        errorAlert('산소포화도 값은 100보다 클 수 없습니다.');
        return;
      }

      const date = new Date(dateRecordRef.current.value);
      const today = new Date();

      if (date > today) {
        errorAlert(`일자는 ${getDateToYMD(today)} 이거나 이전 일자만 가능합니다.`);
        return;
      }

      const isToday = date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()
      const local_date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hoursRef.current.value, minutesRef.current.value)
      const parameter = isToday ? null : getDateToIsoformat(local_date)
      saveAction({ input1: input1.current.value, input2: input2 && input2.current ? input2.current.value : null }, parameter)
    }

    closeHandle();
  }

  const closeHandle = () => {
    const modalWrapRecord = document.querySelector('.modal-wrap.modal-wrap-record');
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    modalWrapRecord.classList.remove('active');

    pagenames.forEach(element => {
      modalWrapRecord.classList.remove(element);
    });

    html.classList.remove('seal');
    body.classList.remove('seal');

    clear();
  }

  return (
    <div className="modal-wrap modal-wrap-record">
      <div className="modal modal-record">
        <div className="modal-record-plate">
          <div className="modal-record-top">
            <div className="modal-record-tit">기록추가</div>
            <button className="btn modal-record-btn modal-record-btn-close" onClick={() => closeHandle()}>
              <img src="/assets/images/common/btn-modal-close-black.svg" alt="닫기" />
            </button>
          </div>
          <div className="modal-record-cont">
            <div className="modal-record-level">
              <label className={`modal-record-level-box ${focus === "input1" ? "focus" : ''}`}>
                <input type="number" className="modal-record-level-num" placeholder={placeholder} ref={input1} onFocus={() => setFocus('input1')} onBlur={() => setFocus('')} />
                <span className="modal-record-level-unit">{unit}</span>
              </label>
            </div>
            {pagename === "bp" &&
              (
                <div className="modal-record-level">
                  <label className={`modal-record-level-box ${focus === "input2" ? "focus" : ''}`}>
                    <input type="number" className="modal-record-level-num" placeholder="이완기 혈압" ref={input2} onFocus={() => setFocus('input2')} onBlur={() => setFocus('')} />
                    <span className="modal-record-level-unit">mmHg</span>
                  </label>
                </div>
              )}
            <div className="modal-record-choice modal-range-choices modal-range-choices-start">
              <label className={`modal-record-choice-datebox ${focus === "date" ? "focus" : ''}`}>
                <input ref={dateRecordRef} type="text" className="modal-record-choice-date" placeholder="날짜선택" readOnly
                  onClick={() => clickAndFocusHandle()} onFocus={() => { setFocus('date'); clickAndFocusHandle() }} onBlur={() => setFocus('')} />
              </label>
              <div className="modal-range-choice-hourbox">
                <select name="range-hours" ref={hoursRef} className="modal-range-choice-shours" value={selectedValue.hour}
                  onChange={(e) => setSelectedValue({ hour: e.target.value, minute: selectedValue.minute })}>
                  {
                    [...Array(24)].map((v, i) => {
                      return <option key={i} value={`${i}`}>{i}</option>
                    })
                  }
                </select>
              </div>
              <div className="modal-range-choice-minutebox">
                <select name="range-minutes" ref={minutesRef} className="modal-range-choice-sminutes" value={selectedValue.minute}
                  onChange={(e) => setSelectedValue({ hour: selectedValue.hour, minute: e.target.value })}>
                  {
                    [...Array(60)].map((v, i) => {
                      return <option key={i} value={`${i}`}>{i}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className="modal-record-calendar-wrap">
              <input type="text" name="record-calendar" ref={recordCalendarRef} className="flatpickr modal-record-calendar" readOnly />
            </div>
          </div>
          <div className="modal-record-bottom">
            <div className="btn btn-save" onClick={() => saveHandle()}>저장하기</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecordModal;

/**
 * 
 * @param {string} pagename 
 */
export const openModalRecord = (pagename) => {
  const modalWrapRecord = document.querySelector('.modal-wrap.modal-wrap-record');
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  if (modalWrapRecord) {
    modalWrapRecord.classList.add('active');
    if (pagenames.includes(pagename))
      modalWrapRecord.classList.add(pagename);
  }

  if (html)
    html.classList.add('seal');
  if (body)
    body.classList.add('seal');
}