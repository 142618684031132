import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth, setAuth, setName } from '../utils/global';
import crypto from 'crypto'
import SosModal, { openSosModal } from '../components/modal/sos-modal';

import { successAlert, warningAlert, errorAlert } from "../utils/alert";

const Login = () => {
  const initData = Object.freeze({
    phone: '',
    password: '',
  });

  const [data, setData] = useState(initData);

  const [focus, setFocus] = useState('');
  const [fadeOut, setFadeOut] = useState(false);

  const [page] = useState('login');
  const pagename = 'login';
  const pagetxt = '로그인';

  const handleFocus = (e) => {
    setFocus(e.target.name);
  }

  const handleBlur = (e) => {
    setFocus('');
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };

  const [isChecked, setChecked] = useState(true);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    axios
      .post('/api/v1/auth/signin', {
        phone: data.phone,
        password: crypto.createHash('sha256').update(data.password).digest("hex"),
        autologin: isChecked,
      })
      .then((response) => {
        const token = response.data.token;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        if (response.statusText === 'OK') {
          setAuth(response.data.auth);
          setName(response.data.name);
          if (getAuth() === "1")
            navigate('/main');
          else if (getAuth() === "0")
            navigate('/mngr');
        }
        else successAlert(response.status);
      })
      .catch((error) => {
        errorAlert("전화번호 또는 비밀번호가 틀렸습니다.");
      });
  };

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="wrap wrap-mobile">
      <style>{`
        .fade-out {
          animation: fadeOut 1s forwards;
        }
        
        @keyframes fadeOut {
          from { opacity: 1; }
          to   { opacity: 0; }
        }
        `}</style>
      <div className={`layout-mobile layout-${page}`}>
        {/* <div className='splash' /> */}
        <div className={`wrap-${pagename}`}>

          <SosModal />
          <header className={`header header-${page}`}>
          </header>

          <section className={`${pagename}-sect sect-typing`}>
            <h2 className={`${pagename}-typing-tit`}>{pagetxt} 해주세요.</h2>
            <div className={`${pagename}-typing-input-boxes`}>
              <div className={`input-box ${pagename}-typing-input-box ${focus === "phone" ? "focus" : ""}`}>
                <input type="tel" className={`input-txt ${pagename}-typing-input ${pagename}-typing-input-tel`}
                  name="phone"
                  onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}
                  required placeholder="전화번호를 입력해주세요." />
              </div>
              <div className={`${pagename}-typing-input-box input-box ${focus === "password" ? "focus" : ""}`}>
                <input type="password" className={`input-txt ${pagename}-typing-input ${pagename}-typing-input-pw`}
                  name="password"
                  onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleOnKeyPress}
                  required placeholder="비밀번호를 입력해주세요." />
              </div>
            </div>
            <div className={`${pagename}-typing-info`}>
              <div className={`${pagename}-typing-auto-wrap`}>
                <label className={`${pagename}-typing-auto`}>
                  <input type="checkbox" name={`${pagename}-typing-auto-check`} className={`${pagename}-typing-auto-check`} checked={isChecked} onChange={({ target: { checked } }) => setChecked(checked)} />
                  <div className={`${pagename}-typing-auto-checkbox`}></div>
                  <span className={`${pagename}-typing-auto-txt`}>자동 로그인</span>
                </label>
              </div>
              <div className={`${pagename}-typing-btns`}>
                <button type="button" className={`${pagename}-typing-btn btn-find`} onClick={() => warningAlert('준비중입니다.')}>비밀번호 찾기</button>
                <button type="button" className={`${pagename}-typing-btn btn-join`} onClick={() => navigate('/signup')}>회원가입</button>
              </div>
            </div>
            <div className={`${pagename}-typing-login`}>
              <button type="button" className={`${pagename}-typing-btn btn-login`} onClick={handleSubmit}>로그인</button>
            </div>
          </section>

          <section className={`${pagename}-sect sect-emergency`}>
            <button type="button" className={`btn ${pagename}-emergency-btn btn-emergency-round`} onClick={() => openSosModal()}></button>
          </section>

          <section className={`${pagename}-sect sect-alert ${fadeOut ? "fade-out" : ""}`}>
            <div className={`${pagename}-alert`} >
              <div className="txt">비밀번호 재등록은 PC에서만 가능합니다.</div>
              <button type="button" className={`btn ${pagename}-alert-btn btn-close`} onClick={() => setFadeOut(true)}>닫기</button>
            </div>
          </section>

        </div>

      </div>
    </div>
  );
};

export default Login;
