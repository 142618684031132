import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';

const safetyUrl = {
  true: '/api/v1/mngr/safety/state',
  false:'/api/v1/user/safety/state',
}

export const SafetyContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean, userId : number | null}} param0 
 * @returns 
 */
export const SafetyProvider = ({ children, allow_url, isMngr = false, userId = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [safety, setSafety] = useState('normal');
  const [safetyData, setSafetyData] = useState(null);
  useEffect(() => {
    if(isMngr && !userId){
      return;
    }
   
    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
    const requset = () => {
      getAxiosInterceptors(navigate)
        .get(safetyUrl[isMngr], isMngr && userId != null ? {params : {user : userId}} : undefined)
        .then((response) => {
          if (response.data.msg === "success") {
            const sensorArr = [response.data.smoke, response.data.gas, response.data.fire];
            const isDanger = sensorArr.every((v) => v);
            const isAllEmpty = sensorArr.every((v) => v === undefined)
            setSafety(isAllEmpty ? null : isDanger ? "danger" : "normal");
            setSafetyData(
              {
                dangerous: isAllEmpty ? null : isDanger,
                disabled: isAllEmpty,
                smoke: response.data.smoke,
                gas: response.data.gas,
                fire: response.data.fire,
              }
            );
          }
          else setSafetyData({ msg: "NO DATA" });
        });
      }
    

    requset();
      const interval = setInterval(() => {
        requset();
      }, 50000);

      return () => clearInterval(interval);
    }
  }, [location.pathname]);

  return (
    <SafetyContext.Provider value={{ safety, setSafety, safetyData, setSafetyData }}>
      {children}
    </SafetyContext.Provider>
  )
}