import React, {useEffect, useState} from 'react';
// import '../style/sos.css';
import { useNavigate } from 'react-router-dom';
import getSmsUrl from '../utils/sms-linking';

const Sos = () => {
  const navigate = useNavigate();
  const closeBtnClick = (event) => {
    navigate('/main');
  };

  const [countDown, setCountDown] = useState(null);
  useEffect(() => {
    if (countDown === 0) {
      setCountDown(null)
      SosReport("위급한 상황입니다.")
    }
    
    if (!countDown) return;

    const intervalId = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countDown]);
  
  useEffect(() =>{
    setCountDown(10);
  }, []);

  const SosReport = (msg) =>{
    const uri = getSmsUrl('119', msg);
    if(uri){
      window.open(uri);
    }
    else{
      alert("모바일 환경에서 시도해 주십시오.");
    }
  }

  return (
    <div className='div-container'>
      <div>
        <button className='sos-element' onClick={(() => SosReport("집에 강도가 들었습니다."))}>강도</button>
        <button className='sos-element' onClick={() => SosReport("호흡에 문제가 있습니다.")}>호흡곤란</button>
      </div>
      <div>
        <button className='sos-element' onClick={() => SosReport("가슴이 아픕니다.")}>가슴통증</button>
        <button className='sos-element' onClick={() => SosReport("넘어져서 움직일 수가 없습니다.")}>넘어짐</button>
      </div>
      {countDown && <span>{countDown}초 동안 무반응시 자동으로 119 문자를 발송합니다.</span>}
      <div style={{ cursor: "pointer", color:"blue" }} onClick={closeBtnClick}>Close</div>
    </div>
  );
}

export default Sos;