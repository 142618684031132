import React, { useState, useContext, useEffect } from "react";
import { getWeekOnlyYMD, getTodayOnlyYMD } from "../../utils/date";

import { StateContext, SafetyContext, DoorLastContext } from "../../components/provider/main-provider";

import MainDetailHeader, { openModalRange } from "../../components/header/main/main-detail-header";
import { ActivitySectCurrent, ActivitySectChartTime, ActivitySectChartDate } from "../../components/activity";
import Bottom from "../../components/bottom";

const page = 'sub';
const pagename = 'ai';
const pagetxt = '활동정보';

const MainDoor = () => {

  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);
  const { doorLastData } = useContext(DoorLastContext);

  const [calState, setCalState] = useState('time'); // time, date
  const [rangeMode, setRangeMode] = useState('single');

  const [dateSingle, setDateSingle] = useState(getTodayOnlyYMD());
  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());

  useEffect(() => {
    if (calState === "time")
      setDateSingle(new Date(dateSingle));
    else if (calState === "date")
      setDateRange({ ...dateRange });
  }, [calState]);

  const changeDateRange = (sd, ed) => {
    if (calState === "time")
      setDateSingle(sd);
    else if (calState === "date")
      setDateRange({ start: sd, end: ed });
  }

  return (
    <div className="wrap wrap-mobile">
      <div className={`layout-mobile layout-${page}`}>

        <div className={`wrap-${pagename}`}>

          <MainDetailHeader page={page} pagetxt={pagetxt} state={state} mode={rangeMode} safety={safety} rangeSaveAction={changeDateRange} />

          <ActivitySectCurrent pagename={pagename} subpagename="door" sensorLastData={doorLastData} onoffText={{on : "열림", off : "닫힘"}} isUsingSubnameInSwiper={true} />

          <section className={`${pagename}-sect sect-chart`}>
            <div className={`${pagename}-chart-door-wrap active`}>
              <div className={`${pagename}-chart-top`}>
                <h3 className={`sect-tit ${pagename}-chart-tit`}>문 열림 추이</h3>
                <div className={`${pagename}-chart-tabs ${pagename}-chart-door-tabs`}>
                  <div className={`${pagename}-chart-tab ${pagename}-chart-door-tab ${calState === 'time' ? "active" : ""}`} onClick={() => setCalState('time')}>시간별</div>
                  <div className={`${pagename}-chart-tab ${pagename}-chart-door-tab ${calState === 'date' ? "active" : ""}`} onClick={() => setCalState('date')}>날짜별</div>
                </div>
                <button type="button" className={`${pagename}-chart-btn-date`} onClick={() => { setRangeMode(calState === 'time' ? 'single' : 'range'); openModalRange() }}>기간설정</button>
              </div>
              <div className={`${pagename}-chart-cont`}>
                <div className={`${pagename}-chart-graphs-door-time ${calState === 'time' ? "active" : ""}`}>
                  <ActivitySectChartTime pagename={pagename} url="/api/v1/user/activity/door/day" sensorLastData={doorLastData} date={dateSingle} color="#8667C4" />
                </div>
                <div className={`${pagename}-chart-graphs-door-date ${calState === 'date' ? "active" : ""}`}>
                  <ActivitySectChartDate pagename={pagename} url="/api/v1/user/activity/door/range" sensorLastData={doorLastData} dateRange={dateRange} color="#8667C4" />
                </div>
              </div>

            </div>
          </section>

          <Bottom pagename={pagename} />

        </div>

      </div>
    </div>

  );
}

export default MainDoor;
