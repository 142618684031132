import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';

export const DashboardCheckContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>}} param0 
 * @returns 
 */
export const DashboardCheckProvider = ({ children, allow_url }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [dashboardCheckData, setDashboardCheckData] = useState([]);

  useEffect(() => {
    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      const requset = () => {
        setLoading(true);

        getAxiosInterceptors(navigate)
          .get('/api/v1/user/health/check', { params: { offset: new Date().getTimezoneOffset() } })
          .then((response) => {
            setLoading(false);
            setDashboardCheckData(response.data || []);
          })
          .catch((err) => { setLoading(false); });
      }

      requset();
      const interval = setInterval(() => {
        requset();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [location.pathname]);

  return (
    <DashboardCheckContext.Provider value={{ loading, dashboardCheckData, setDashboardCheckData }}>
      {children}
    </DashboardCheckContext.Provider>
  )
}