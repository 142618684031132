import React from "react";

/**
 * 
 * @param {{helpOsActive : string, setHelpOsActive : (arg : string) => void}} param0 
 * @returns 
 */
export const OsHelpModal = ({ helpOsActive, setHelpOsActive }) => {
  return (
    <div className={`modal-wrap modal-wrap-help oxygen-saturation ${helpOsActive}`}>
      <div className={`modal modal-help`}>
        <div className={`modal-help-plate`}>
          <div className={`modal-help-top`}>
            <div className={`modal-help-tit`}>산소포화도</div>
            <button className={`btn modal-help-btn modal-help-btn-close`}>
              <img src="/assets/images/common/btn-modal-close-black.svg" alt="닫기" onClick={() => setHelpOsActive('')} />
            </button>
          </div>
          <div className={`modal-help-cont`}>
            <div className={`modal-help-img`}>
              <img src="/assets/images/pages/oxygenSaturation/bg-help-os.png" alt="산소포화도란" />
            </div>
            <div className={`modal-help-txt`}>
              산소포화도(Saturation of partial pressure oxygden, SpO2)는 <span className={`highlight`}>산소와 헤모글로빈의 결합 비율</span>을 나타내는 것으로 혈액 안의 산소 농도를 나타냄
            </div>
          </div>
          <div className={`modal-help-bottom`}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OsHelpModal;