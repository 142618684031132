import React, { useState, useEffect, memo } from "react";

/**
 * @param {number} to 
 * @param {(value:number) => void} setValue 
 */
export const numberCounter = (to, setValue) => {
  let start = null;
  let duration = 3000; // 애니메이션의 지속 시간 (밀리초)
  let from = 0; // 시작 값

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  }

  const animate = (timestamp) => {
    if (!start) start = timestamp;
    let elapsed = timestamp - start;
    let progress = Math.min(elapsed / duration, 1);
    progress = easeInOutQuad(progress);

    setValue(Math.ceil(from + (to - from) * progress));
    if (elapsed < duration) {
      requestAnimationFrame(animate);
    }
  }

  requestAnimationFrame(animate);
}

/**
 * @param {{targetNum : number}} param0 
 */
const _NumCountAnimate = ({ targetNum }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    numberCounter(targetNum, setCount);
  }, [targetNum]);

  return <>{count}</>
};

export const NumCountAnimate = memo(_NumCountAnimate);
export default NumCountAnimate;