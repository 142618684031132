import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';
import { getDateToYMDOrMD } from '../../utils/date';

const motionWeekUrl = {
  true: '/api/v1/mngr/activity/motion/week',
  false:'/api/v1/user/activity/motion/week',
}

export const MotionWeekContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean}} param0 
 * @returns 
 */
export const MotionWeekProvider = ({ children, allow_url, isMngr = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [motionWeekData, setMotionWeekData] = useState(null);

  useEffect(() => {
    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      getAxiosInterceptors(navigate)
        .get(motionWeekUrl[isMngr])
        .then((response) => {
          if (response.data.msg === 'success') {
            const value = response.data;
            
            const threeMonthAgo = new Date();
            threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
            const end = new Date(value.range.end);

            const data = {
              disabled: end < threeMonthAgo,
              start: value.range.start ? getDateToYMDOrMD(new Date(value.range.start)) : '시작일자',
              end: value.range.end ? getDateToYMDOrMD(end) : '종료일자',
              hour: parseInt(value.time / 3600),
            }
            setMotionWeekData(data);
          }
          else setMotionWeekData({ msg: "NO DATA" });
        });
    }

  }, [location.pathname]);

  return (
    <MotionWeekContext.Provider value={{ motionWeekData, setMotionWeekData }}>
      {children}
    </MotionWeekContext.Provider>
  )
}