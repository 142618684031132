import React from 'react';
import { StateContext, StateProvider } from './state-provider';
import { SafetyContext, SafetyProvider } from './safety-provider';
import { DashboardActiveContext, DashboardActiveProvider } from './dashboard-active-provider';
import { BpLastContext, BpLastProvider } from './bp-provider';
import { BsLastContext, BsLastProvider } from './bs-provider';
import { OsLastContext, OsLastProvider } from './oxy-provider';
import { TvWeekContext, TvWeekProvider } from './tv-week-provider';
import { TvLastContext, TvLastProvider } from './tv-last-provider';
import { DashboardMainContext, DashboardMainProvider } from './dashboard-main-provider';
import { MotionWeekContext, MotionWeekProvider } from './motion-week-provider';
import { MotionLastContext, MotionLastProvider } from './motion-last-provider';
import { DoorWeekContext, DoorWeekProvider } from './door-week-provider';
import { DoorLastContext, DoorLastProvider } from './door-last-provider';
import { DashboardCheckContext, DashboardCheckProvider } from './dashboard-check-provider';

export const MainContext = React.createContext(null);

export const MainProvider = ({ children }) => {
  return (
    <MainContext.Provider value={{}}>
      <StateProvider>
        <SafetyProvider>
          <DashboardActiveProvider>
            <BpLastProvider allow_url={["/main", "/main/bp"]}>
              <BsLastProvider allow_url={["/main", "/main/bs"]}>
                <OsLastProvider allow_url={["/main", "/main/oxy"]}>
                  <TvWeekProvider allow_url={["/main"]}>
                    <TvLastProvider allow_url={["/main/tv"]}>
                      <MotionLastProvider allow_url={["/main/motion"]}>
                        <MotionWeekProvider allow_url={["/main"]}>
                          <DoorLastProvider allow_url={["/main/door"]}>
                            <DoorWeekProvider allow_url={["/main"]}>
                              <DashboardMainProvider allow_url={['/main', '/main/temp']}>
                                <DashboardCheckProvider allow_url={['/main/check']}>
                                  {children}
                                </DashboardCheckProvider>
                              </DashboardMainProvider>
                            </DoorWeekProvider>
                          </DoorLastProvider>
                        </MotionWeekProvider>
                      </MotionLastProvider>
                    </TvLastProvider>
                  </TvWeekProvider>
                </OsLastProvider>
              </BsLastProvider>
            </BpLastProvider>
          </DashboardActiveProvider>
        </SafetyProvider>
      </StateProvider>
    </MainContext.Provider>
  );
}


export {
  DashboardActiveContext,
  StateContext,
  SafetyContext,
  BpLastContext,
  BsLastContext,
  OsLastContext,
  TvLastContext,
  TvWeekContext,
  DashboardMainContext,
  MotionLastContext,
  MotionWeekContext,
  DoorLastContext,
  DoorWeekContext,
  DashboardCheckContext
};