import React, { useState, useContext, useEffect } from "react";
import { getWeekOnlyYMD, getTodayOnlyYMD } from "../../utils/date";

import { StateContext, SafetyContext, MotionLastContext } from "../../components/provider/main-provider";
import { ActivitySectCurrent, ActivitySectChartTime, ActivitySectChartDate } from "../../components/activity";

import MainDetailHeader, { openModalRange } from "../../components/header/main/main-detail-header";
import Bottom from "../../components/bottom";

const page = 'sub';
const pagename = 'ia';
const pagetxt = '실내활동';

const MainMotion = () => {


  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);
  const { motionLastData } = useContext(MotionLastContext);

  const [calState, setCalState] = useState('time'); // time, date
  const [rangeMode, setRangeMode] = useState('single');

  const [dateSingle, setDateSingle] = useState(getTodayOnlyYMD());
  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());

  useEffect(() => {
    if (calState === "time")
      setDateSingle(new Date(dateSingle));
    else if (calState === "date")
      setDateRange({ ...dateRange });
  }, [calState]);

  const changeDateRange = (sd, ed) => {
    if (calState === "time")
      setDateSingle(sd);
    else if (calState === "date")
      setDateRange({ start: sd, end: ed });
  }

  return (
    <div className="wrap wrap-mobile">
      <div className={`layout-mobile layout-${page}`}>

        <div className={`wrap-${pagename}`}>

          <MainDetailHeader page={page} pagetxt={pagetxt} mode={rangeMode} state={state} safety={safety} rangeSaveAction={changeDateRange} />

          <ActivitySectCurrent pagename={pagename} subpagename="active"
            sensorLastData={motionLastData} onoffText={{ on: "활동중", off: "없음" }}
            isUsingSubnameInSwiper={true} isUsingSubnameInSlide={false} />

          <section className={`${pagename}-sect sect-chart`}>
            <div className={`${pagename}-chart-wrap active`}>
              <div className={`${pagename}-chart-top`}>
                <h3 className={`sect-tit ${pagename}-chart-tit`}>{pagetxt} 추이</h3>
                <div className={`${pagename}-chart-tabs ${pagename}-chart-tabs`}>
                  <div className={`${pagename}-chart-tab ${pagename}-chart-tab ${calState === 'time' ? "active" : ""}`} onClick={() => setCalState('time')}>시간별</div>
                  <div className={`${pagename}-chart-tab ${pagename}-chart-tab ${calState === 'date' ? "active" : ""}`} onClick={() => setCalState('date')}>날짜별</div>
                </div>
                <button type="button" href="#" className={`${pagename}-chart-btn-date`} onClick={() => { setRangeMode(calState === 'time' ? 'single' : 'range'); openModalRange() }}>기간설정</button>
              </div>
              <div className={`${pagename}-chart-cont`}>
                <div className={`${pagename}-chart-graphs-time ${calState === 'time' ? "active" : ""}`}>
                  <ActivitySectChartTime pagename={pagename} url="/api/v1/user/activity/motion/day" sensorLastData={motionLastData} date={dateSingle} color="#92ACEB" />
                </div>
                <div className={`${pagename}-chart-graphs-date ${calState === 'date' ? "active" : ""}`}>
                  <ActivitySectChartDate pagename={pagename} url="/api/v1/user/activity/motion/range" sensorLastData={motionLastData} dateRange={dateRange} color="#92ACEB" />
                </div>
              </div>
            </div>
          </section>

          <Bottom pagename={pagename} />

        </div>

      </div>
    </div>
  );
}

export default MainMotion;