import React, { useEffect, useContext, useState, useRef } from "react";
import MainDashBoard from '../../components/main-dash-board';

import { getName } from "../../utils/global";
import { calculate } from "../../utils/calculate";

import MainHeader from "../../components/header/main/main-header"
import Bottom from "../../components/bottom"

import { StateContext, SafetyContext, DashboardActiveContext } from "../../components/provider/main-provider";

const healthStates = [
  {
    en: 'good',
    ko: '건강',
  },
  {
    en: 'careful',
    ko: '조심',
  },
  {
    en: 'warning',
    ko: '주의',
  },
  {
    en: 'danger',
    ko: '위험',
  },
  {
    en: 'emergency',
    ko: '응급',
  },
];

const pagename = 'dashboard';
const pagetxt = '';

const Main = () => {
  const [page, setPage] = useState('main');

  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);

  const h = useRef(window.getComputedStyle(document.body).getPropertyValue('--main-btns-h'));
  const [beforeTouchedY, setBeforeTouchedY] = useState(0);
  const { dashboardActive, setDashboardActive } = useContext(DashboardActiveContext);

  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    if (html)
      html.classList.add('seal');

    if (body)
      body.classList.add('seal');

    return () => {
      if (html)
        html.classList.remove('seal');

      if (body)
        body.classList.remove('seal');
    }
  }, []);

  useEffect(() => {
    if (dashboardActive)
      setPage('sub');
    else
      setPage('main');
  }, [dashboardActive]);

  const handleTouchStart = (e) => {
    if (e.target.classList.contains('btn')) return;
    if (e.target.classList.contains('dashboard-states-btns') || e.target.closest('.dashboard-states-btns')) return;
    if (e.target.classList.contains('modal') || e.target.closest('.modal')) return;

    setBeforeTouchedY(e.touches[0].clientY);
    h.current = window.getComputedStyle(document.body).getPropertyValue('--main-btns-h');
  }

  const handleTouchMove = (e) => {
    if (e.target.classList.contains('btn')) return;
    if (e.target.classList.contains('dashboard-states-btns') || e.target.closest('.dashboard-states-btns')) return;
    if (e.target.classList.contains('modal') || e.target.closest('.modal')) return;

    const y = e.changedTouches[0].clientY - beforeTouchedY;

    if (y > 0)
      setDashboardActive(false);

    document.documentElement.style.setProperty("--main-btns-h", `${h.current} + ${y} * 1px`);
  }

  const handleTouchEnd = (e) => {
    if (e.target.classList.contains('btn')) return;
    if (e.target.classList.contains('dashboard-states-btns') || e.target.closest('.dashboard-states-btns')) return;
    if (e.target.classList.contains('modal') || e.target.closest('.modal')) return;

    h.current = window.getComputedStyle(document.body).getPropertyValue('--main-btns-h');

    const hValue = calculate(h.current.replaceAll('px', ''));

    if (hValue >= -30) {
      setDashboardActive(false);

      document.documentElement.style.setProperty("--main-btns-h", `-30px`);
    }
    else if (hValue < -165) {
      setDashboardActive(true);

      document.documentElement.style.setProperty("--main-btns-h", `-215px - 30px`);
    }
  }

  const handleMouseDown = (e) => {
    if (e.target.classList.contains('btn')) return;
    if (e.target.classList.contains('dashboard-states-btns') || e.target.closest('.dashboard-states-btns')) return;

    setBeforeTouchedY(e.clientY);
  }

  const handleMouseUp = (e) => {
    if (e.target.classList.contains('btn')) return;
    if (e.target.classList.contains('dashboard-states-btns') || e.target.closest('.dashboard-states-btns')) return;

    let deltaY = e.clientY - beforeTouchedY;
    if (deltaY === 0) return;

    setDashboardActive(deltaY < 0 ? true : false);
  }

  return (
    <div className={`wrap wrap-mobile`}>
      <div className="layout-mobile">
        <div className="wrap-dashboard" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>

          <MainHeader page={page} pagetxt={pagetxt} state={state} safety={safety} />

          <section className={`dashboard-sect sect-result ${state}`}>
            <ul className="dashboard-result-list">
              <li className="dashboard-result-li">
                <p>{getName()}님은</p>
                <p><strong className="dashboard-result-current">{healthStates.find(({ en }) => en === state).ko}</strong> 상태입니다.</p>
              </li>
              <li className="dashboard-result-li">
                <div className="dashboard-result-face"></div>
              </li>
            </ul>
          </section>

          <MainDashBoard state={state} dashboardActive={dashboardActive} />
          <Bottom pagename={pagename} />

        </div>

      </div>
    </div>
  )
}
export default Main