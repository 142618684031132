import React from "react";
import RecordModal, { openModalRecord } from "../../modal/record-modal";
import MngrDetailHeader, { openModalRange } from "./mngr-detail-header";

/**
 * 
 * @param {{
* page : string, 
* pagename : string | undefined, 
* pagetxt : string, 
* state : string, 
* safety : string, 
* rangeSaveAction : ((start_date : string, end_date : string) => void) | undefined, 
* recordSaveAction : ((value : {input1:number, input2:number|undefined}, date : string) => void) | undefined
* }} param0 
* @returns 
*/
export const MngrHealthHeader = ({ page, pagename, pagetxt, rangeSaveAction, recordSaveAction }) => {
  return (
    <>
      <RecordModal pagename={pagename} saveAction={recordSaveAction} />
      <MngrDetailHeader page={page} pagetxt={pagetxt} rangeSaveAction={rangeSaveAction} />
    </>
  );
};

export default MngrHealthHeader;
export { openModalRange, openModalRecord };