import React, { useEffect } from "react";

import { RtTempSectCurrent, RtHumiSectCurrent } from "./activity-sect-current";
import { RtSectChartDate } from "./activity-sect-chart-date";
import { RtSectChartTime } from "./activity-sect-chart-time";

import Swiper from "swiper";
import { Navigation } from 'swiper/modules'

/**
 * 
 * @param {{setRangeMode : (arg : string) => void, openModalRange : () => void, temp : number, humi : number, 
 *          tab : string, setTab : (arg : string) => void,
 *          calTempState : string, setCalTempState : (arg : string) => void,
 *          calHumiState : string, setCalHumiState : (arg : string) => arg,
 *          dateSingle : Date, setDateSingle : (arg : Date) => void, 
 *          dateRange : {start : Date, end : Date}, setDateRangeSingle(arg : {start : date, end : Date}) => void},
 *          isMngr : boolean, userId : number | null} param0 
 * @returns 
 */
export const RtSwiper = (
  { setRangeMode, openModalRange,
    temp, humi,
    dateSingle, setDateSingle,
    dateRange, setDateRange,
    tab, setTab,
    calTempState, setCalTempState,
    calHumiState, setCalHumiState, isMngr = false, userId = null }) => {
  useEffect(() => {
    const swiperOn = () => {
      const swiperNextBtn = document.querySelector('.swiper-rt-btn-next');
      const swiperPrevBtn = document.querySelector('.swiper-rt-btn-prev');

      const handleSlideChange = (swiper) => {
        const index = swiper.activeIndex;

        swiperPrevBtn.classList.remove('swiper-rt-btn-temperature');
        swiperNextBtn.classList.remove('swiper-rt-btn-humidity');

        if (index === 0) {
          swiperNextBtn.classList.add('swiper-rt-btn-humidity');
          setTab('temp');
        } else if (index === 1) {
          swiperPrevBtn.classList.add('swiper-rt-btn-temperature');
          setTab('humi');
        }
      };

      return {
        init: () => {
          swiperNextBtn.classList.add('swiper-rt-btn-humidity');
        },
        slideChange: handleSlideChange
      };
    };

    const swiperRt = new Swiper('.swiper.swiper-rt', {
      modules: [Navigation],
      allowTouchMove: false,
      navigation: {
        prevEl: ".swiper-rt-btn-prev",
        nextEl: ".swiper-rt-btn-next",
      },
      on: swiperOn(),
    });

    return () => {
      swiperRt.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    if ((tab === 'temp' && calTempState === 'time') || (tab === 'humi' && calHumiState === 'time'))
      setDateSingle(new Date(dateSingle));
    else if ((tab === 'temp' && calTempState === 'date') || (tab === 'humi' && calHumiState === 'date'))
      setDateRange({ ...dateRange });
  }, [tab, calHumiState, calTempState]);

  return (
    <div className="swiper swiper-rt">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className={`rt-temperature`}>
            <RtTempSectCurrent temp={temp} />

            <section className={`rt-sect sect-chart`}>
              <div className={`rt-chart-top`}>
                <h3 className={`sect-tit rt-chart-tit`}>온도 추이</h3>
                <div className={`rt-chart-tabs rt-chart-temperature-tabs`}>
                  <div className={`rt-chart-tab rt-chart-temperature-tab ${calTempState === 'time' ? "active" : ""}`} onClick={() => setCalTempState('time')}>시간별</div>
                  <div className={`rt-chart-tab rt-chart-temperature-tab ${calTempState === 'date' ? "active" : ""}`} onClick={() => setCalTempState('date')}>날짜별</div>
                </div>
                <button type="button" className={`rt-chart-btn-date`} onClick={() => { setRangeMode(calTempState === 'time' ? 'single' : 'range'); openModalRange() }}>기간설정</button>
              </div>
              <div className={`rt-chart-cont`}>
                <div className={`rt-chart-graphs-temperature-time ${calTempState === 'time' ? "active" : ""}`}>
                  <RtSectChartTime name="temperature" unit="℃" url={`/api/v1/${isMngr ? "mngr" : "user"}/activity/temp/day`} date={dateSingle} userId={userId}/>
                </div>
                <div className={`rt-chart-graphs-temperature-date ${calTempState === 'date' ? "active" : ""}`}>
                  <RtSectChartDate name="temperature" unit="℃" url={`/api/v1/${isMngr ? "mngr" : "user"}/activity/temp/range`} dateRange={dateRange} userId={userId}/>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="swiper-slide">
          <div className={`rt-humidity`}>
            <RtHumiSectCurrent humi={humi} />

            <section className={`rt-sect sect-chart`}>
              <div className={`rt-chart-top`}>
                <h3 className={`sect-tit rt-chart-tit`}>습도 추이</h3>
                <div className={`rt-chart-tabs rt-chart-humidity-tabs`}>
                  <div className={`rt-chart-tab rt-chart-humidity-tab ${calHumiState === 'time' ? "active" : ""}`} onClick={() => setCalHumiState('time')}>시간별</div>
                  <div className={`rt-chart-tab rt-chart-humidity-tab ${calHumiState === 'date' ? "active" : ""}`} onClick={() => setCalHumiState('date')}>날짜별</div>
                </div>
                <button type="button" className={`rt-chart-btn-date`} onClick={() => { setRangeMode(calHumiState === 'time' ? 'single' : 'range'); openModalRange() }}>기간설정</button>
              </div>
              <div className={`rt-chart-cont`}>
                <div className={`rt-chart-graphs-humidity-time ${calHumiState === 'time' ? "active" : ""}`}>
                  <RtSectChartTime name="humidity" unit="%" url={`/api/v1/${isMngr ? "mngr" : "user"}/activity/humi/day`} date={dateSingle} userId={userId}/>
                </div>
                <div className={`rt-chart-graphs-humidity-date ${calHumiState === 'date' ? "active" : ""}`}>
                  <RtSectChartDate name="humidity" unit="%" url={`/api/v1/${isMngr ? "mngr" : "user"}/activity/humi/range`} dateRange={dateRange} userId={userId}/>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className={`swiper-button-prev swiper-rt-btn-prev`}></div>
      <div className={`swiper-button-next swiper-rt-btn-next`}></div>
    </div>
  );
};

export default RtSwiper;