import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';
import { getDateToYMDOrMD } from '../../utils/date';

const dashboardMainUrl = {
  true: '/api/v1/mngr/activity/main',
  false:'/api/v1/user/activity/main',
}

export const DashboardMainContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean, userId : number | null}} param0 
 * @returns 
 */
export const DashboardMainProvider = ({ children, allow_url, isMngr = false, userId = null  }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tempData, setTempData] = useState(null);
  const [humiData, setHumiData] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const refresh = () => setTrigger(!trigger);

  useEffect(() => {
    if(isMngr && !userId){
      return;
    }

    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      const requset = () => {
        getAxiosInterceptors(navigate)
          .get(dashboardMainUrl[isMngr], isMngr && userId != null ? {params : {user : userId}} : undefined)
          .then((response) => {
            for(const [key, value] of Object.entries(response.data)){
              const threeMonthAgo = new Date();
              threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
              const time = new Date(value.time);

              value.disabled = time < threeMonthAgo;
              value.time = getDateToYMDOrMD(time);

              if (key === "temperature") {
                value.figure = value.value < 18 ? "추움" : value.value < 28 ? "보통" : "더움"
              }
            }

            setTempData(response.data.temperature || { msg: "NO DATA" });
            setHumiData(response.data.humidity || { msg: "NO DATA" });
          });
      }

      requset();
      const interval = setInterval(() => {
        requset();
      }, 300000);

      return () => clearInterval(interval);
    }

  }, [location.pathname, trigger]);

  return (
    <DashboardMainContext.Provider value={{ tempData, setTempData, humiData, setHumiData, refresh }}>
      {children}
    </DashboardMainContext.Provider>
  )
}