import React, { useEffect, useRef, useState } from "react";
import flatpickr from "flatpickr";
import { Korean } from "flatpickr/dist/l10n/ko.js";

import { getWeekOnlyYMD, getTodayOnlyYMD } from "../../utils/date";

import { warningAlert } from "../../utils/alert";

/**
 * 
 * @param {{mode : string, saveAction : ((start_date : string, end_date : string) => void) | null}} param0 
 * @returns 
 */
export const RangeModal = ({ mode = "range", saveAction }) => {
  const calendarRef = useRef();
  const sDateRef = useRef();
  const sHoursRef = useRef();
  const sMinutesRef = useRef();
  const eDateRef = useRef();
  const eHoursRef = useRef();
  const eMinutesRef = useRef();
  const [eSelectedValue, setESelectedValue] = useState({ hour: "23", minute: "59" })
  const [rangeCalendar, setRangeCalendar] = useState(null);
  const [focus, setFocus] = useState('');

  const singleDate = useRef(getTodayOnlyYMD());
  const rangeDate = useRef(getWeekOnlyYMD());

  useEffect(() => {
    if (calendarRef.current) {
      const rangeCalendar = flatpickr(calendarRef.current, {
        clickOpens: false,
        disableMobile: true,
        mode: mode === "single" || mode === "range" ? mode : "range",
        dateFormat: mode === "single" ? "Y-m-d" : "Y-m-d H:i",
        defaultDate: mode === "single" ? singleDate.current : [rangeDate.current.start, rangeDate.current.end],
        locale: Korean,
        onReady: (selectedDates) => {
          updateInputs(selectedDates);
        },
        onChange: (selectedDates, dateStr, instance) => {
          updateInputs(selectedDates);
          let beforeT = instance.calendarContainer.style.top.replace('px', '');
          let currentT = beforeT - -45;
          instance.calendarContainer.style.top = currentT + 'px';
        },
        onClose: (selectedDates, dateStr, instance) => {
          let beforeT = instance.calendarContainer.style.top.replace('px', '');
          let currentT = beforeT - -25;
          instance.calendarContainer.style.top = currentT + 'px';
        },
      });

      rangeCalendar.setDate(formatSetDate(sDateRef.current.value, sHoursRef.current.value, sMinutesRef.current.value, eDateRef.current.value, eSelectedValue.hour, eSelectedValue.minute), 'Y-m-d H:i ~ Y-m-d H:i');
      setRangeCalendar(rangeCalendar);

      return () => {
        rangeCalendar.destroy();
      }
    }
  }, [mode]);

  const updateDate = () => {
    if (mode === "single") {
      singleDate.current = new Date(sDateRef.current.value);
    }
    else {
      const start = new Date(sDateRef.current.value);
      start.setHours(sHoursRef.current.value, sMinutesRef.current.value)
      const end = new Date(eDateRef.current.value);
      end.setHours(eHoursRef.current.value, eMinutesRef.current.value)

      rangeDate.current.start = start;
      rangeDate.current.end = end;
    }
  }

  const updateInputs = (selectedDates) => {
    if (selectedDates[0]) {
      sDateRef.current.value = formatDateTime(selectedDates[0]);
      updateDate();
    }
    if (selectedDates[1]) {
      eDateRef.current.value = formatDateTime(selectedDates[1]);
      updateDate();
    }
  }

  const formatDateTime = (date) => {
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) + '-' + date.getDate().toString().padStart(2, 0);
  }

  const clickAndFocusHandle = () => {
    if (rangeCalendar && rangeCalendar.isEnabled()) {
      rangeCalendar.open();
    }
  };

  const changeHandle = () => {
    updateDate();
    rangeCalendar.setDate(formatSetDate(sDateRef.current.value, sHoursRef.current.value, sMinutesRef.current.value, eDateRef.current.value, eHoursRef.current.value, eMinutesRef.current.value), 'Y-m-d H:i ~ Y-m-d H:i');
  }

  const formatSetDate = (startD, startH, startM, endD, endH, endM) => {
    let d;
    if (endD && endH && endM) {
      let start = `${startD} ${startH.toString().padStart(2, 0)}:${startM.toString().padStart(2, 0)}`;
      let end = `${endD} ${endH.toString().padStart(2, 0)}:${endM.toString().padStart(2, 0)}`;
      d = `${start} ~ ${end}`;
    } else {
      d = `${startD}`;
    }
    return d;
  }

  const saveHandle = () => {
    const start = new Date(sDateRef.current.value);
    start.setHours(sHoursRef.current.value, sMinutesRef.current.value);

    if (mode === "single") {
      if(start > new Date()){
        warningAlert('일자는 오늘보다 미래일 수 없습니다.');
        return;
      }

      if (saveAction) {
        saveAction(start, null);
      }
    }
    else {
      const end = new Date(eDateRef.current.value);
      end.setHours(eHoursRef.current.value, eMinutesRef.current.value);

      if (start > end) {
        warningAlert('시작 일시가 종료 일시보다 뒤일 수 없습니다.');
        return;
      }

      if(end > new Date().setDate(new Date().getDate() + 1)) {
        warningAlert('종료 일시는 현재보다 미래일 수 없습니다.');
        return;
      }
  
      if (saveAction) {
        saveAction(start, end);
      }
    }

    closeHandle();
  }

  const closeHandle = () => {
    setFocus('')

    const modalWrapRange = document.querySelector('.modal-wrap.modal-wrap-range');
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    modalWrapRange.classList.remove('active');
    html.classList.remove('seal');
    body.classList.remove('seal');
  }

  return (
    <div className="modal-wrap modal-wrap-range">
      <div className="modal modal-range">
        <div className="modal-range-plate">
          <div className="modal-range-top">
            <div className="modal-range-tit">{mode === "single" ? "일자설정" : "기간설정"}</div>
            <button className="btn modal-range-btn modal-range-btn-close" onClick={() => closeHandle()}>
              <img src="/assets/images/common/btn-modal-close-black.svg" alt="닫기" />
            </button>
          </div>
          <div className="modal-range-cont">
            <div className="modal-range-choices modal-range-choices-start">
              <label className={`modal-range-choice-datebox ${focus === "sdate" ? "focus" : ''}`}>
                <input type="text" readOnly ref={sDateRef} className="modal-range-choice-date modal-range-choice-sdate" placeholder="날짜선택" onClick={() => { clickAndFocusHandle(); setFocus("sdate"); }} />
              </label>
              <div className="modal-range-choice-hourbox" style={mode === "single" ? { display: "none" } : {}}>
                <select name="range-hours" ref={sHoursRef} className="modal-range-choice-shours" onClick={() => { changeHandle() }}>
                  {
                    [...Array(24)].map((v, i) => {
                      return <option key={i} value={`${i}`}>{i}</option>
                    })
                  }
                </select>
              </div>
              <div className="modal-range-choice-minutebox" style={mode === "single" ? { display: "none" } : {}}>
                <select name="range-minutes" ref={sMinutesRef} className="modal-range-choice-sminutes" onClick={() => { changeHandle() }}>
                  {
                    [...Array(60)].map((v, i) => {
                      return <option key={i} value={`${i}`}>{i}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className="modal-range-choices modal-range-choices-end" style={mode === "single" ? { display: "none" } : {}}>
              <label className={`modal-range-choice-datebox  ${focus === "edate" ? "focus" : ''}`}>
                <input type="text" readOnly ref={eDateRef} className="modal-range-choice-date modal-range-choice-edate" placeholder="날짜선택" onClick={() => { clickAndFocusHandle(); setFocus("edate"); }} />
              </label>
              <div className="modal-range-choice-hourbox">
                <select name="range-hours" ref={eHoursRef} value={eSelectedValue.hour} className="modal-range-choice-ehours" onClick={() => { changeHandle(); }} onChange={(e) => { setESelectedValue({ hour: e.target.value, minute: eSelectedValue.minute }); }}>
                  {
                    [...Array(24)].map((v, i) => {
                      return <option key={i} value={`${i}`}>{i}</option>
                    })
                  }
                </select>
              </div>
              <div className="modal-range-choice-minutebox">
                <select name="range-minutes" ref={eMinutesRef} value={eSelectedValue.minute} className="modal-range-choice-eminutes" onClick={() => { changeHandle() }} onChange={(e) => { setESelectedValue({ minute: e.target.value, hour: eSelectedValue.hour }); }}>
                  {
                    [...Array(60)].map((v, i) => {
                      return <option key={i} value={`${i}`}>{i}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <div className="modal-range-calendar-wrap">
              <input type="num" name="range-calendar" ref={calendarRef} className="flatpickr modal-range-calendar" />
            </div>
          </div>
          <div className="modal-range-bottom" onClick={() => saveHandle()}>
            <div className="btn btn-save">적용하기</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RangeModal;

const openModalRange = () => {
  const modalWrapRange = document.querySelector('.modal-wrap.modal-wrap-range');
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  if (modalWrapRange)
    modalWrapRange.classList.add('active');

  if (html)
    html.classList.add('seal');

  if (body)
    body.classList.add('seal');
}

export { openModalRange };