import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosInterceptors from "../../utils/axios-interceptors";

import { StateContext, SafetyContext } from "../../components/provider/main-provider";

import MainHealthHeader, { openModalRange, openModalRecord } from "../../components/header/main/main-health-header";
import { BpLastContext } from "../../components/provider/main-provider";
import BpHelpModal from "../../components/modal/bp-help-modal";
import BpSectCurrent from "../../components/health/bp-sect-current";
import HealthSectChart from "../../components/health/health-sect-chart";
import Bottom from "../../components/bottom";

import { getWeekOnlyYMD, getDateToYMDHHMMOrMDHHMM, getDateToIsoformat } from "../../utils/date";

import { successAlert, errorAlert } from "../../utils/alert";
import { makeEchartOption } from "../../components/echart-graph";

const page = 'sub';
const pagename = 'bp';
const pagetxt = '혈압';

const dateParser = (date) => {
  const dateObj = new Date(date);
  return getDateToYMDHHMMOrMDHHMM(dateObj);
}

const valueConverter = (value) => value.toFixed(0);

const MainBP = () => {
  const navigate = useNavigate();

  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);
  const { bpData, refresh } = useContext(BpLastContext);

  const [helpActive, setHelpActive] = useState('');

  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());
  const [optionBpLineDate, setOptionBpLineDate] = useState(null);

  useEffect(() => {
    getAxiosInterceptors(navigate)
      .get(
        '/api/v1/user/health/bp/range',
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end) } }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setOptionBpLineDate(
            makeEchartOption(data.map((value) => dateParser(value.date)),
              [{
                name: "sys",
                data: data.map((value) => valueConverter(value.systolic)),
                color: '#F4BA2F'
              },
              {
                name: "dia",
                data: data.map((value) => valueConverter(value.diastolic)),
                color: '#C98A2A',
              }])
          );
        }
      });
  }, [dateRange, bpData]);

  const changeDateRange = (sd, ed) => {
    setDateRange({ start: sd, end: ed })
  }

  const recordSave = (value, date) => {
    if (value.input1 && value.input2) {
      getAxiosInterceptors(navigate)
        .post(
          '/api/v1/user/health/bp/add',
          {
            systolic: value.input1,
            diastolic: value.input2,
            date: date
          }
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200) {
            successAlert("혈압 데이터가 정상적으로 등록되었습니다.");
            refresh();
          }
        })
        .catch(error => {
          errorAlert("저장에 실패했습니다.");
        });
    }
  }

  return (
    <div className="wrap wrap-mobile">
      <div className="layout-mobile layout-sub">

        <div className="wrap-bp">

          <MainHealthHeader page={page} pagename={pagename} pagetxt={pagetxt} state={state} safety={safety} rangeSaveAction={changeDateRange} recordSaveAction={recordSave} />

          <BpHelpModal helpBpActive={helpActive} setHelpBpActive={setHelpActive} />

          <BpSectCurrent bpData={bpData} setHelpBpActive={setHelpActive} />

          <HealthSectChart pagename="bp" pagetxt={pagetxt} openModalRange={openModalRange} dateRange={dateRange} echartOption={optionBpLineDate} />

          <Bottom pagename={pagename} writeOnClick={() => openModalRecord("blood-pressure")} />

        </div>

      </div>
    </div>

  );
}

export default MainBP;
