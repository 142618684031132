import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Table from "../../components/table";
import Modal from "../../components/modal";
import DeviceNew from "./device-new";
import DeviceMove from "./device-update";
import getAxiosInterceptors from "../../utils/axios-interceptors";
// import "../style/main.css";
// import "../style/input.css";
// import { useTable } from 'react-table';
// import Modal from 'react-modal';

const Device = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState({
    insert: false,
    move: false,
  });
  const [info, setInfo] = useState(null);
  const [pendingList, setPendingList] = useState(null);
  const [selectedSensorId, setSelectedSensorId] = useState(null);
  const columnData = [
    {
      accessor: "device_id",
      Header: "센서 ID",
      Cell: ({ value }) => (
        <button
          onClick={() => {
            setSelectedSensorId(value); // 선택된 센서 ID 값을 설정
            openModal("move");
          }}
        >
          {value}
        </button>
      ),
    },
    {
      accessor: "device_type",
      Header: "센서 종류",
    },
    {
      accessor: "install_location",
      Header: "설치 장소",
    },
    // {
    //   accessor: "reg_date",
    //   Header: "등록 일시",
    // },
  ];

  const columns = useMemo(() => columnData, []);
  const data = useMemo(() => info || [], [info]);

  // const modalToggle = (v) => {
  //   if(v == 'insert'){
  //     setModalIsOpen(!(modalIsOpen.insert));
  //   }
  //   else{
  //     setModalIsOpen(!(modalIsOpen.move));
  //   }
  //   console.log()
  // }
  const openModal = (modalType) => {
    setModalIsOpen((prevState) => ({
      ...prevState,
      [modalType]: true,
    }));
  };

  const closeModal = (modalType) => {
    setModalIsOpen((prevState) => ({
      ...prevState,
      [modalType]: false,
    }));
  };

  useEffect(() => {
    getAxiosInterceptors(navigate)
      .get("/api/v1/user/device/list")
      .then((response) => {
        if (response.data.msg === "success") {
          const value = response.data.data;
          console.log(value);
          const i = [];
          for (const v of value) {
            const d = {};
            columnData.forEach((column, index) => {
              d[column.accessor] = v[index];
            });
            i.push(d);
          }
          console.log(i);
          setInfo(i);
        } else setInfo({ msg: "센서가 등록되지 않았습니다." });
      });
      getAxiosInterceptors(navigate)
      .get("/api/v1/user/device/waiting")
      .then((response) => {
        if (response.data.msg === "success") {
          const value = response.data.data;
          console.log('pending device list: ', value);
          setPendingList(value);
        }
      });
  }, []);

  return (
    <div className="div-container">
      {pendingList && <div className="alarm-button">
        <button onClick={() => openModal("insert")}>추가된 장치</button>
      </div>}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>장치 관리</h2>
      </div>
      <div
        style={{
          marginTop: "5px",
          marginBottom: "5px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      >
        <Table columns={columns} data={data} />
      </div>
      <Modal
        open={modalIsOpen["insert"]}
        close={() => closeModal("insert")}
        header="장치 추가"
      >
        <DeviceNew pendingList={pendingList}></DeviceNew>
      </Modal>
      <Modal
        open={modalIsOpen["move"]}
        close={() => closeModal("move")}
        header="장치 설치 위치 변경"
      >
        {/* 선택된 센서 ID 값을 props로 전달 */}
        <DeviceMove selectedSensorId={selectedSensorId}></DeviceMove>
      </Modal>
      <Link to="/main">첫 페이지</Link>
    </div>
  );
};
export default Device;
