import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosInterceptors from "../../utils/axios-interceptors";
// import "../../style/common.css";
// import "../../style/input.css";

const DeviceMove = ({ selectedSensorId }) => {
  // props로 defaultDeviceId를 받아옵니다.
  const navigate = useNavigate();

  const initData = Object.freeze({
    device_id: selectedSensorId, // 초기 device_id 값을 defaultDeviceId로 설정합니다.
    gateway_id: "",
    install_location: "",
  });

  const [data, setData] = useState(initData);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.device_id && data.install_location) {
      try {
        const response = await getAxiosInterceptors(navigate).patch(
          "/api/v1/user/device/update",
          {
            device_id: data.device_id,
            install_location: data.install_location,
          }
        );

        if (response.status === 200) {
          alert("요청이 성공적으로 처리되었습니다."); // 응답 내용을 문자열로 출력
          navigate("/device");
        } else {
          alert(`Error: ${response.statusText}`); // 응답의 상태 텍스트를 출력
        }
      } catch (e) {
        console.error("Error:", e);
        alert(`Error: ${e.message}`); // 발생한 에러의 메시지를 출력
      }
    } else {
      alert("기기 정보를 입력해주세요.");
    }
  };

  return (
    <div className="device-insert-input-box">
      <form>
        <p> 장치 ID </p>
        <input
          type="text"
          name="device_id"
          value={data.device_id} // value를 data.device_id로 설정하여 기본값으로 센서 ID를 표시합니다.
          readOnly // 수정이 불가능하도록 readOnly 속성을 추가합니다.
          placeholder="게이트웨이에 등록된 ID"
          required
        />

        <p> 설치 위치 </p>
        <input
          type="text"
          name="install_location"
          placeholder="변경된 기기 설치 위치"
          required
          onChange={handleChange}
        />

        <div>
          <button onClick={handleSubmit} type="submit">
            변경
          </button>
        </div>
      </form>
    </div>
  );
};
export default DeviceMove;
