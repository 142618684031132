const getTodayOnlyYMD = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate());
}

const getTodayToYMD = () => getDateToYMD(undefined);

const getWeekAgoToYMD = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return getDateToYMD(date);
}

/**
 * @param {Date} date 
 * @returns 
 */
const getDateToYMD = (date, yearFormat = "-", monthFormat = "-", dateFormat = "") => {
  date = date || new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${yearFormat}${month}${monthFormat}${day}${dateFormat}`;
}

/**
 * @param {Date} date
 * @returns 
 */
const getDateToMD = (date, monthFormat = "-", dateFormat = "") => {
  date = date || new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}${monthFormat}${day}${dateFormat}`;
}

const getWeekOnlyYMD = () => {
  const today = new Date();
  const end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  end.setDate(end.getDate() + 1);
  end.setMilliseconds(end.getMilliseconds() - 1);

  const start = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  start.setDate(start.getDate() - 7);
  return {
    start: start,
    end: end,
  }
}

const getWeek = () => {
  const end = new Date();
  const start = new Date(end);
  start.setDate(start.getDate() - 7);
  return {
    start: start,
    end: end,
  }
}

const getDateToYMDIsoformat = (date) => {
  date = date || new Date();
  date = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  return date.toISOString();
}

const getDateToIsoformat = (date) => {
  date = date || new Date();
  return date.toISOString();
}

const getDateToYMDOrMD = (date, yearFormat = "년 ", monthFormat = "월 ", dateFormat = "일") => {
  date = date || new Date();
  const today = new Date();

  const isCurrentYear = date.getYear() === today.getYear();

  return isCurrentYear ? getDateToMD(date, monthFormat, dateFormat) : getDateToYMD(date, yearFormat, monthFormat, dateFormat)
}

const getDateToYMDHHMMOrMDHHMM = (date, isAmPmDiv = true, yearFormat = "년 ", monthFormat = "월 ", dateFormat = "일") => {
  date = date || new Date();
  const today = new Date();

  const isCurrentYear = date.getYear() === today.getYear();

  if (isAmPmDiv) {
    const isNoon = date.getHours() === 12 && date.getMinutes() === 0;
    const isPm = date.getHours() > 11
    const hour = isPm ? date.getHours() - 12 ? date.getHours() - 12 : 12 : date.getHours() ? date.getHours() : 12;

    if (isNoon)
      return `${isCurrentYear ? getDateToMD(date, monthFormat, dateFormat) : getDateToYMD(date, yearFormat, monthFormat, dateFormat)} 정오`
    else if (isPm)
      return `${isCurrentYear ? getDateToMD(date, monthFormat, dateFormat) : getDateToYMD(date, yearFormat, monthFormat, dateFormat)} 오후 ${hour}:${date.getMinutes().toString().padStart(2, '0')}`
    else
      return `${isCurrentYear ? getDateToMD(date, monthFormat, dateFormat) : getDateToYMD(date, yearFormat, monthFormat, dateFormat)} 오전 ${hour}:${date.getMinutes().toString().padStart(2, '0')}`
  }

  return `${isCurrentYear ? getDateToMD(date, monthFormat, dateFormat) : getDateToYMD(date, yearFormat, monthFormat, dateFormat)} ${date.getHours()}:${date.getMinutes()}`
}

/**
 * 
 * @param {Date} start 
 * @param {Date} end 
 */
const getYMDDateArray = (start, end) => {
  const start_YMD = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  const end_YMD = new Date(end.getFullYear(), end.getMonth(), end.getDate()).setDate(end.getDate() + 1);

  const result = []
  for (start_YMD; start_YMD < end_YMD; start_YMD.setDate(start_YMD.getDate() + 1))
    result.push(getDateToYMDOrMD(start_YMD));
  
  return result;
}

export default getTodayToYMD;
export { getTodayOnlyYMD, getTodayToYMD, getWeekAgoToYMD, getDateToYMD, getDateToMD, getWeekOnlyYMD, getWeek, getDateToIsoformat, getDateToYMDOrMD, getDateToYMDIsoformat, getDateToYMDHHMMOrMDHHMM, getYMDDateArray };

