import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import crypto from 'crypto'

import { errorAlert } from "../utils/alert";

const SignUp = () => {
  const initData = Object.freeze({
    name: '',
    phone: '',
    email: '',
    gw_id: '',
    password: '',
  });
  const [data, setData] = useState(initData);

  const [focus, setFocus] = useState('');

  const [page] = useState('join');
  const pagename = 'join';
  const pagetxt = '회원가입';

  const handleFocus = (e) =>{
    setFocus(e.target.name);
  }

  const handleBlur = (e) => {
    setFocus('');
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value.trim() });
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    if(!(/^[가-힣]{2,4}$/g).test(data.name)){
      errorAlert("올바른 이름을 입력해주세요.");
      return;
    }

    if(!(/^[0-9]{11}$/g).test(data.phone)){
      errorAlert("\"-\"없이 숫자로 이루어진 휴대폰 번호를 적어주세요.");
      return;
    }

    if(!(/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/g).test(data.password)){
      errorAlert("영문, 숫자, 특수문자를 섞은 8자리에서 20자리이하의 문자로 설정해주세요.");
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
    };

    axios
      .post('/api/v1/auth/signup', {
        name: data.name,
        phone: data.phone,
        email: data.email,
        gw_id: data.gw_id,
        password: crypto.createHash('sha256').update(data.password).digest("hex"),
        // password: data.password,
      }, { headers })
      .then((response) => {
        response.data.msg === 'success' ? navigate('/main') : errorAlert(response.data.msg);
      })
      .catch((error) => {
        
      });
  };

  return (
    <div className="wrap wrap-mobile">
      <div className={`layout-mobile layout-${page}`}>

        <div className={`wrap-${pagename}`}>

          <header className={`header header-${page}`}>
            <div className="btn header-btn header-btn-back" onClick={() => navigate(-1)}></div>
            <h2 className="header-tit">{pagetxt}</h2>
          </header>

          <section className={`${pagename}-sect sect-typing`}>
            <div className={`${pagename}-typing-input-cate`}>
              {/* <!-- 유효성 검사 통과시 complete 추가 --> */}
              <div className={`${pagename}-typing-input-cate ${pagename}-typing-input-cate-name`}>
                <div className={`${pagename}-typing-input-cate-tit`}>이름</div>
                <div className={`input-box ${pagename}-typing-input-box ${focus === "name" ? "focus" : ""}`}>
                  <input type="text" className={`input-txt ${pagename}-typing-input ${pagename}-typing-input-name`} name="name" placeholder="이름을 입력해주세요." onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} />
                </div>
              </div>
              <div className={`${pagename}-typing-input-cate ${pagename}-typing-input-cate-tel`}>
                <div className={`${pagename}-typing-input-cate-tit`}>전화번호</div>
                <div className={`${pagename}-typing-input-box input-box ${focus === "phone" ? "focus" : ""}`}>
                  <input type="tel" className={`input-txt ${pagename}-typing-input ${pagename}-typing-input-tel`} name="phone" placeholder="전화번호를 입력해주세요." onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>
                </div>
              </div>
              <div className={`${pagename}-typing-input-cate ${pagename}-typing-input-cate-pw`}>
                <div className={`${pagename}-typing-input-cate-tit`}>비밀번호</div>
                <div className={`${pagename}-typing-input-box input-box ${focus === "password" ? "focus" : ""}`}>
                  <input type="password" className={`input-txt ${pagename}-typing-input ${pagename}-typing-input-pw`} name="password" placeholder="비밀번호를 입력해주세요." onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>
                </div>
              </div>
              <div className={`${pagename}-typing-input-cate ${pagename}-typing-input-cate-gateway`}>
                <div className={`${pagename}-typing-input-cate-tit`}>게이트웨이 모델 아이디</div>
                <div className={`${pagename}-typing-input-box input-box ${focus === "gw_id" ? "focus" : ""}`}>
                  <input type="text" className={`input-txt ${pagename}-typing-input ${pagename}-typing-input-gateway`} name="gw_id" placeholder="게이트웨이 모델 아이디를 입력해주세요." onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}/>
                </div>
              </div>
            </div>
          </section>

          <section className={`${pagename}-sect sect-bottom`}>
            <div className={`${pagename}-bottom-login-wrap`}>
              <span className={`${pagename}-bottom-login-ask`}>이미 회원이신가요?</span>
              <button type="button" className={`btn ${pagename}-bottom-btn ${pagename}-bottom-btn-login`} onClick={() => navigate('/login')}>로그인</button>
            </div>
            <div className={`${pagename}-bottom-join-wrap`}>
              <button type="button" className={`btn ${pagename}-bottom-btn ${pagename}-bottom-btn-join`} onClick={() => handleSubmit()}>가입하기</button>
            </div>
          </section>

        </div>

      </div>
    </div>
  );
};

export default SignUp;
