import React from "react";
import SafetyAlarmModal, { openModalAlarm } from "../../modal/safety-alarm-modal";
import MngrHeader from "./mngr-header";

/**
 * 
 * @param {{
* page : string, 
* pagetxt : string, 
* url : string, devicetype : string, userid : number
* }} param0 
* @returns 
*/
export const MngrSafetyHeader = ({ page, pagetxt, url, devicetype, userId }) => {
  return (
    <>
      <SafetyAlarmModal url={url} devicetype={devicetype} userId={userId} />
      <MngrHeader page={page} pagetxt={pagetxt} />
    </>
  )
};

export default MngrSafetyHeader;
export { openModalAlarm };