import React from "react";

/**
 * 
 * @param {{helpBsActive : string, setHelpBsActive : (arg : string) => void}} param0 
 * @returns 
 */
export const BsHelpModal = ({ helpBsActive, setHelpBsActive }) => {
  return (
    <div className={`modal-wrap modal-wrap-help blood-sugar ${helpBsActive}`}>
      <div className={`modal modal-help`}>
        <div className={`modal-help-plate`}>
          <div className={`modal-help-top`}>
            <div className={`modal-help-tit`}>당뇨병</div>
            <button className={`btn modal-help-btn modal-help-btn-close`}>
              <img src="/assets/images/common/btn-modal-close-black.svg" alt="닫기" onClick={() => setHelpBsActive('')} />
            </button>
          </div>
          <div className={`modal-help-cont`}>
            <div className={`modal-help-img`}>
              <img src="/assets/images/pages/bloodSugar/bg-help-bs.png" alt="당뇨병이란" />
            </div>
            <div className={`modal-help-txt`}>
              당뇨병이란 <span className={`highlight`}>혈액 내의 포도당(혈당)이 높아서 소변으로 포도당이 넘쳐 나오는 데서 붙은 이름</span>입니다. 탄수화물을 섭취하면 위장관에서 소화효소에 의해 기본 구성성분인 포도당으로 변한 다음 혈액으로 흡수됩니다.
            </div>
          </div>
          <div className={`modal-help-bottom`}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BsHelpModal;