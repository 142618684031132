const window_resize = () => {
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  setScreenSize();
  
  window.addEventListener('resize', setScreenSize);
 
  return () => window.removeEventListener('resize', setScreenSize);
}



export { window_resize};