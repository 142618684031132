import React from "react";
import { useTable } from "react-table";
// import "../style/table.css"; // 변경된 부분

// useTable에다가 작성한 columns와 data를 전달한 후 아래 4개의 props를 받아온다
function Table({ columns, data, handleChange = null, handleSubmit = null }) {
  const {
    getTableProps, // table propsz
    getTableBodyProps, // table body props
    headerGroups, // 헤더
    rows, // 행 데이터
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((col) => (
              <th {...col.getHeaderProps()}>{col.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
