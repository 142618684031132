import React from "react";
import { getDateToYMDOrMD } from "../../utils/date";
import EchartLineGraph from "../echart-graph";

/**
 * 
 * @param {{pagename : string, pagetxt : string, openModalRange : () => void, dateRange : { start : Date, end : Date }, echartOption : object}} param0 
 * @returns 
 */
export const HealthSectChart = ({ pagename, pagetxt, openModalRange, dateRange, echartOption}) => {
  return (
    <section className={`${pagename}-sect sect-chart`}>
      <div className={`${pagename}-chart-top`}>
        <h3 className={`sect-tit ${pagename}-chart-tit`}>{pagetxt} 추이</h3>
        <button type="button" className={`btn btn-range-date ${pagename}-chart-btn-date`} onClick={() => openModalRange()}>
          기간설정
        </button>
      </div>
      <div className={`${pagename}-chart-cont`}>
        <div className={`${pagename}-chart-graph-wrap`}>
          <div className={`${pagename}-chart-graph-date`}>{getDateToYMDOrMD(dateRange.start)} ~ {getDateToYMDOrMD(dateRange.end)}</div>
          <div className={`${pagename}-echart-line-wrap`}>
            <EchartLineGraph id={`${pagename}-echart-line-date`} echartOption={echartOption} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HealthSectChart;