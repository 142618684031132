import axios from "axios";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/**
 * @param {NavigateFunction} navigate 
 * @returns {AxiosInstance}
 */
const getAxiosInterceptors = (navigate) => {
  const instance = axios.create();

  instance.interceptors.response.use(
    response => response, // 성공 시 그대로 반환
    async error => {
      if (!error)
        return Promise.reject(error);

      const originalRequest = error.config;
      if (originalRequest && error.response && error.response.status === 401 && !originalRequest._retry) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios.get('/refresh')
            .then(res => {
              if (res.status === 200) {
                const token = res.data.token;
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                processQueue(null, token);
                return token;
              } else {
                processQueue(new Error('Refresh token fail'), null);
              }
            })
            .catch(err => {
              processQueue(err, null);
              navigate && navigate('/login');
            })
            .finally(() => { isRefreshing = false });
        }

        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest._retry = true;
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return instance(originalRequest);
        }).catch(err => {
          axios.defaults.headers.common['Authorization'] = ``;
          instance.defaults.headers.common['Authorization'] = ``;
          originalRequest.headers['Authorization'] = ``;
          return Promise.reject(err);
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default getAxiosInterceptors;