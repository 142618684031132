import React, { useEffect, useState } from "react";

/**
 * 
 * @param {{subname : {en : string, ko : string}, safetyData : { dangerous: boolean, disabled: boolean, smoke: boolean, gas: boolean, fire: boolean, msg : string | null}}} param0 
 * @returns 
 */
export const SafetyCurrentBox = ({ subname, safetyData }) => {
  const [danger, setDanger] = useState(null)
  useEffect(() => {
    if (safetyData) {
      const danger = Reflect.get(safetyData, subname.en);
      setDanger(danger);
    }
    else setDanger(null);
  }, [safetyData]);

  return <>
    {(!safetyData || danger != null) && (
      <div className={`safety-current-box safety-current-box-${subname.en} ${danger  ? "danger" : "normal"}`}>
        <div className="safety-current-box-txt">
          <div className={`safety-current-cate ${danger ? "danger" : "normal"}`}>
            <div className="txt">{subname.ko}</div>
            <div className="icon"></div>
          </div>
          <div className="safety-current-state">
            {(danger != null && (danger ? "위험" : "정상")) || (!safetyData && "loading...")}
            {safetyData && safetyData.msg ? safetyData.msg : ""}
          </div>
        </div>
        <div className="safety-current-img"></div>
      </div>
    )}
  </>
}

export default SafetyCurrentBox;