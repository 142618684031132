import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

/**
 * 
 * @param {{id : string | undefined, echartOption : object}} param0 Use the "makeEchartOption" function in echartOption parameter 
 */
export const EchartLineGraph = ({ id, className = 'echart-line', echartOption }) => {
  const divElement = useRef(null);
  useEffect(() => {
    let echart;
    if (echartOption && divElement.current && divElement.current.clientHeight && divElement.current.clientWidth) {
      echart = echarts.init(divElement.current);

      echart.setOption(echartOption);

      return () => {
        echart.dispose();
      };
    }
  }, [echartOption]);

  return (
    <div ref={divElement} id={id} className={className}></div>
  )
};

/**
 * 
 * @param {Array} xData 
 * @param {Array<{name : string | null, data : Array, color : string}>} yData
 * @param {((arg : any) => string) | null} echartTooltipFormat 
 * @param {{min : number, max : number}} dataRange 
 */
export const makeEchartOption = (xData, yData = [], echartTooltipFormat = null, dataRange = {}) => {
  const zoom = xData.length > 20 ? 25 : xData.length > 10 ? 50 : 75;
  return {
    xAxis: {
      type: 'category',
      data: xData,
      show: false,
    },
    yAxis: {
      type: 'value',
      show: false,
      ...dataRange,
    },
    grid: {
      left: '-6%',
      right: '-6%',
      bottom: '0%',
    },
    tooltip: {
      trigger: 'axis',
      valueFormatter: echartTooltipFormat
    },
    series: yData.map((obj) => {
      return {
        name: obj.name || null,
        data: obj.data,
        type: 'line',
        symbol: 'circle',
        symbolSize: 6,
        smooth: true,
        color: obj.color,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: obj.color
            },
            {
              offset: 1,
              color: '#F8F8FB00'
            }
          ])
        },
      }
    }),
    dataZoom: [
      {
        show: false,
        start: 0,
        end: zoom,
      },
      {
        type: 'inside',
        start: 0,
        end: zoom,
        zoomLock: true,
      }
    ],
  }
};

export default EchartLineGraph;