import React, { useEffect } from "react";
import Swiper from "swiper";
import * as echarts from "echarts";

/**
 * 
 * @param {{pagename : string, subpagename : string, 
 *          sensorLastData : Object<string, [boolean, string]>, 
 *          onoffText : {on : string, off : string}, 
 *          isUsingSubnameInSwiper : boolean, isUsingSubnameInSlide : boolean}} param0 
 * @param {} param0.isUsingSubnameInSwiper 스와이퍼 내에서 서브네임 사용 유무
 * @param {} param0.isUsingSubnameInSlide 스와이퍼 슬라이드 내에서 서브네임 사용 유무
 * @returns 
 */
export const ActivitySectCurrent = ({
  pagename, subpagename = '', sensorLastData,
  onoffText = ['ON', 'OFF'],
  isUsingSubnameInSwiper = false, isUsingSubnameInSlide = true
}) => {
  useEffect(() => {
    const swiperCurrent = new Swiper(`.swiper.swiper-${pagename}-current${isUsingSubnameInSwiper ? "-" + subpagename : ''}`, {
      slidesPerView: "auto",
    });

    return () => {
      swiperCurrent.destroy(true, true);
    };
  }, []);

  return (
    <section className={`${pagename}-sect sect-current`}>
      <div className={`${pagename}-current-top`}>
        <h3 className={`sect-tit ${pagename}-current-tit`}>현재상태</h3>
        <div className="wt-current-total">총{Object.keys(sensorLastData).length}개</div>
      </div>
      <div className={`${pagename}-current-cont`}>
        <div className={`${pagename}-current-${subpagename}-wrap active`}>
          <div className={`swiper swiper-${pagename}-current${isUsingSubnameInSwiper ? "-" + subpagename : ''}`}>
            <div className="swiper-wrapper">
              {sensorLastData ?
                Object
                  .entries(sensorLastData)
                  .map(([key, value]) => (
                    <div className={`swiper-slide ${pagename}-current-slide`} key={key}>
                      <div className={`${pagename}-current-${subpagename} ${value && value.length > 1 && value[0] ? "active" : ""}`}>
                        <div className={`${pagename}-current${isUsingSubnameInSlide ? "-" + subpagename : ''}-icon`}></div>
                        <div className={`${pagename}-current${isUsingSubnameInSlide ? "-" + subpagename : ''}-place`}>{value && value.length > 1 && value[1] ? value[1] : key}</div>
                        <div className={`${pagename}-current${isUsingSubnameInSlide ? "-" + subpagename : ''}-state`}>{value && value.length > 1 && value[0] ? onoffText.on : onoffText.off}</div>
                      </div>
                    </div>
                  ))
                : (<div>loading...</div>)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ActivitySectCurrent;

/**
 * 
 * @param {{temp : number}} param0 
 * @returns 
 */
export const RtTempSectCurrent = ({ temp }) => {
  useEffect(() => {
    const rtGaugeTem = document.getElementById('rt-echart-gauge-temperature');
    const echartRtGaugeTem = echarts.init(rtGaugeTem);
    let optionRtGauge = {
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 260,
          endAngle: -80,
          min: -10,
          max: 40,
          splitNumber: 10,
          itemStyle: {
            color: {
              type: 'linear',
              x: 1,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: '#F4642F'
              }, {
                offset: 1, color: '#FF9001'
              }]
            }
          },
          progress: {
            show: true,
            width: 7.5,
            roundCap: true,
          },
          pointer: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 7.5,
            },
            roundCap: true,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '-0%'],
            fontFamily: 'SUIT',
            fontSize: 54,
            fontWeight: '900',
            formatter: '{value}',
            color: '#F4642F'
          },
          data: [
            {
              value: 20
            }
          ]
        }
      ]
    };

    echartRtGaugeTem.setOption(optionRtGauge);
    const colorChangeEchartRt = (tem) => {
      if (tem <= 0) {
        echartRtGaugeTem.setOption({
          series: [{
            itemStyle: {
              color: {
                colorStops: [{
                  offset: 0, color: '#7E79F3'
                }, {
                  offset: 1, color: '#489ACC'
                }]
              }
            },
            detail: {
              color: '#7E79F3'
            }
          }]
        })
        document.querySelectorAll('.rt-unit-temperature').forEach((element) => { element.style.color = '#7E79F3'; });
      } else if (tem > 0 && tem <= 15) {
        echartRtGaugeTem.setOption({
          series: [{
            itemStyle: {
              color: {
                colorStops: [{
                  offset: 0, color: '#387CEE'
                }, {
                  offset: 1, color: '#18C3C5'
                }]
              }
            },
            detail: {
              color: '#387CEE'
            }
          }]
        })
        document.querySelectorAll('.rt-unit-temperature').forEach((element) => { element.style.color = '#387CEE'; });
      } else if (tem > 15 && tem <= 26) {
        echartRtGaugeTem.setOption({
          series: [{
            itemStyle: {
              color: {
                colorStops: [{
                  offset: 0, color: '#F4642F'
                }, {
                  offset: 1, color: '#FF9001'
                }]
              }
            },
            detail: {
              color: '#F4642F'
            }
          }]
        });
        document.querySelectorAll('.rt-unit-temperature').forEach((element) => { element.style.color = '#F4642F'; });
      } else if (tem > 26) {
        echartRtGaugeTem.setOption({
          series: [{
            itemStyle: {
              color: {
                colorStops: [{
                  offset: 0, color: '#FC312A'
                }, {
                  offset: 1, color: '#FF9001'
                }]
              }
            },
            detail: {
              color: '#FC312A'
            }
          }]
        });
        document.querySelectorAll('.rt-unit-temperature').forEach((element) => { element.style.color = '#FC312A'; });
      }
      echartRtGaugeTem.setOption({
        series: [
          {
            data: [
              {
                value: tem
              }
            ]
          }
        ]
      });
    }
    colorChangeEchartRt(temp);

    return () => {
      echartRtGaugeTem.dispose();
    }
  }, [temp]);

  return (
    <section className={`rt-sect sect-current`}>
      <div className={`rt-current-top`}>
        <h3 className={`sect-tit rt-current-tit`}>현재상태</h3>
      </div>
      <div className={`rt-current-cont`}>
        <div id={`rt-echart-gauge-temperature`}></div>
        <div className={`rt-unit-temperature`}>°C</div>
      </div>
    </section>
  );
}

/**
 * 
 * @param {{humi : number}} param0 
 */
export const RtHumiSectCurrent = ({ humi }) => {
  useEffect(() => {
    document.documentElement.style.setProperty('--humidity', document.querySelector('.rt-val-humidity').dataset.humidity);

    return () => {
      document.documentElement.style.removeProperty('--humidity');
    }
  }, [humi]);

  return (
    <section className={`rt-sect sect-current`}>
      <div className={`rt-current-top`}>
        <h3 className={`sect-tit rt-current-tit`}>현재상태</h3>
      </div>
      <div className={`rt-current-cont`}>
        <div className={`rt-progress-humidity-wrap`}>
          <div className={`rt-progress-humidity`}></div>
          <div className={`rt-progress-humidity-txt`}>
            <div className={`rt-val-humidity`} data-humidity={`${humi}`}>{humi}</div>
            <div className={`rt-unit-humidity`}>%</div>
          </div>
        </div>
      </div>
    </section>
  );
}