import React, { memo } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { errorAlert } from "../utils/alert";

const _Logout = () => {
  const navigate = useNavigate();
  const handleClick = async (e) => {
    axios
      .post('/api/v1/auth/signout')
      .then((response) => {
        if (response.statusText === 'OK') {
          navigate('/login');
          axios.defaults.headers.common['Authorization'] = '';
        }
        else {
          errorAlert(`알 수 없는 이유로 로그아웃에 실패했습니다.\n에러코드 : ${response.status}`);
        }
      })
      .catch((error) => {

      });
  };
  return (
    <span onClick={handleClick}>로그아웃</span>
  )
};

export const Logout = memo(_Logout);
export default Logout;