import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosInterceptors from "../../utils/axios-interceptors";
import { getDateToYMDHHMMOrMDHHMM } from "../../utils/date";

/**
 * 
 * @param {{url : string, devicetype : string, userid : number}} param0 
 * @returns 
 */
export const SafetyAlarmModal = ({ url, devicetype, userId }) => {
  const navigate = useNavigate();
  const [timeList, setTimeList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (devicetype) {
      setLoading(true);
      getAxiosInterceptors(navigate)
        .get(
          url,
          { params: { type: devicetype, user: userId } }
        )
        .then((response) => {
          const data = response.data;
          const list = Reflect.get(data, devicetype);
          if (list.length > 0) {
            setTimeList(list);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [devicetype]);

  const closeHandle = () => {
    const modalWrapAlarm = document.querySelector('.modal-wrap.modal-wrap-alarm');
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    modalWrapAlarm.classList.remove('active');

    html.classList.remove('seal');
    body.classList.remove('seal');
  }

  return (
    <div className={`modal-wrap modal-wrap-alarm`}>
      <div className="modal modal-alarm">
        <div className="modal-alarm-plate">
          <div className="modal-alarm-top">
            <div className="modal-alarm-tit">알람 기록</div>
            <button className="btn modal-alarm-btn modal-alarm-btn-close">
              <img src="/assets/images/common/btn-modal-close-black.svg" alt="닫기" onClick={() => closeHandle()} />
            </button>
          </div>
          <div className="modal-alarm-cont">
            <ul className="modal-alarm-list">
              {
                loading ? (
                  <li className="modal-alarm-li">
                    <div className="modal-alarm-date">Loading...</div>
                  </li>
                ) :
                  (
                    timeList.length > 0 ?
                      timeList.map((value) => (
                        <li className="modal-alarm-li" key={value}>
                          <div className="modal-alarm-date">{getDateToYMDHHMMOrMDHHMM(new Date(value))}</div>
                        </li>
                      )) :
                      (
                        <li className="modal-alarm-li">
                          <div className="modal-alarm-date">알람이 울린적이 없습니다.</div>
                        </li>
                      )
                  )
              }
            </ul>
          </div>
          <div className="modal-alarm-bottom">
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafetyAlarmModal;

export const openModalAlarm = () => {
  const modalWrapAlarm = document.querySelector('.modal-wrap.modal-wrap-alarm');
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  modalWrapAlarm.classList.add('active');

  if (html)
    html.classList.add('seal');
  if (body)
    body.classList.add('seal');
}