import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import getAxiosInterceptors from "../../utils/axios-interceptors";
import { getDateToYMDOrMD, getDateToIsoformat } from "../../utils/date";

import EchartLineGraph, { makeEchartOption } from "../echart-graph";

const dateParser = (date) => {
  const dateObj = new Date(date);
  return `${getDateToYMDOrMD(dateObj)}`;
}

const valueConverter = (value) => parseInt(value);

/**
 * 
 * @param {{pagename : string, url : string, sensorLastData : Object<string, [boolean, string]>, dateRange : { start : Date, end : Date }, color : string, userId : number | null}} param0 
 * @returns 
 */
export const ActivitySectChartDate = ({ pagename, url, sensorLastData, dateRange, color, userId = null }) => {
  const navigate = useNavigate();
  const [optionObj, setOptionObj] = useState({});

  useEffect(() => {
    if (Object.keys(sensorLastData).length > 0) {
      const apiCtrl = new AbortController();
      Object.entries(sensorLastData).map(([key, value]) => {
        getAxiosInterceptors(navigate)
          .get(
            url,
            { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end), device: key, offset: new Date().getTimezoneOffset(), user: userId }, signal : apiCtrl.signal }
          )
          .then((response) => {
            const data = response.data.data;
            if (data.length > 0) {
              const obj = {};
              Reflect.set(obj, key,
                makeEchartOption(data.map((value) => dateParser(value.date)),
                  [{
                    data: data.map((value) => valueConverter(value.hour)),
                    color: color,
                  }],
                  (value) => `${value}시간`, { min: -7, max: 24 }))
              setOptionObj((prevObj) => { return { ...prevObj, ...obj } });
            }
          })
          .catch((err) => {});

        return null;
      });

      return () => apiCtrl.abort();
    }
  }, [sensorLastData, dateRange]);

  return (
    <div className={`${pagename}-chart-graphs`}>
      {sensorLastData ?
        Object
          .entries(sensorLastData)
          .map(([key, value]) => (
            <div className={`${pagename}-chart-graph-wrap`} key={key}>
              <div className={`${pagename}-chart-graph-date`}>{getDateToYMDOrMD(dateRange.start)} ~ {getDateToYMDOrMD(dateRange.end)}</div>
              <div className={`${pagename}-chart-graph-place`}>{value && value.length > 1 && value[1] ? value[1] : key}</div>
              <div className={`${pagename}-echart-line-wrap`}>
                <EchartLineGraph id={`${pagename}-echart-line-date-${key}`} echartOption={Reflect.get(optionObj, key)} />
              </div>
            </div>
          ))
        : (
          <div className={`${pagename}-chart-graph-wrap`}>
            <div className={`${pagename}-chart-graph-date`}></div>
            <div className={`${pagename}-chart-graph-place`}></div>
            <div className={`${pagename}-echart-line-wrap`}>
              <div className="echart-line"></div>
            </div>
          </div>
        )}
    </div>
  );
}

export default ActivitySectChartDate;

/**
 * 
 * @param {{name : string, unit : string, url : string, dateRange : { start : Date, end : Date }, userId : number | null}} param0 
 * @returns 
 */
export const RtSectChartDate = ({ name, unit, url, dateRange, userId = null }) => {
  const navigate = useNavigate();
  const [echartRtOption, setEchartRtOption] = useState(null);
  useEffect(() => {
    getAxiosInterceptors(navigate)
      .get(
        url,
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end), offset: new Date().getTimezoneOffset(), user: userId } }
      )
      .then((response) => {
        const data = response.data.data;
        setEchartRtOption(
          makeEchartOption(
            data.map((value) => dateParser(value.date)),
            [{
              data: data.map((value) => valueConverter(value.value)),
              color: '#F4642F',
            }],
            (value) => `${value}${unit}`)
        );
      });
  }, [dateRange]);

  return (
    <div className={`rt-chart-graphs`}>
      <div className={`rt-chart-graph-wrap`}>
        <div className={`rt-chart-graph-date`}>{getDateToYMDOrMD(dateRange.start)} ~ {getDateToYMDOrMD(dateRange.end)}</div>
        <div className={`rt-chart-graph-place`}></div>
        <div className={`rt-echart-line-wrap`}>
          <EchartLineGraph id={`rt-echart-line-${name}-date-place`} echartOption={echartRtOption} />
        </div>
      </div>
    </div>
  );
}