import React from "react";
import RangeModal, { openModalRange } from "../../modal/range-modal";
import MainHeader from "./main-header";

/**
 * 
 * @param {{
* page : string, 
* pagetxt : string, 
* mode : string
* state : string, 
* safety : string, 
* rangeSaveAction : ((start_date : string, end_date : string) => void) | undefined, 
* }} param0 
* @returns 
*/
export const MainDetailHeader = ({ page, pagetxt, mode = "range", state = 'good', safety = 'normal', rangeSaveAction }) => {
  return (
    <>
      <RangeModal saveAction={rangeSaveAction} mode={mode} />
      <MainHeader page={page} pagetxt={pagetxt} state={state} safety={safety} />
    </>
  )
};

export default MainDetailHeader;
export { openModalRange };