/**
 * @param {string} phoneNumber 
 * @param {string} message 
 */
const getSmsUrl = (phoneNumber, message) => {
  message = encodeURIComponent(message);

  const userAgent = navigator.userAgent.toLocaleLowerCase();

  if (userAgent.search('android') > -1) {
    return `sms:${phoneNumber}?body=${message}`;
  } else if (userAgent.search('iphone') > -1 || userAgent.search('ipad') > -1) {
    return `sms:${phoneNumber}&body=${message}`;
  }

  return ``;
}

export default getSmsUrl;