import React, { useState, useContext } from "react";
import { getWeekOnlyYMD } from "../../utils/date";

import { StateContext, SafetyContext, TvLastContext } from "../../components/provider/main-provider";
import { ActivitySectCurrent, ActivitySectChartDate } from "../../components/activity";

import MainDetailHeader, { openModalRange } from "../../components/header/main/main-detail-header";
import Bottom from "../../components/bottom";

const page = 'sub';
const pagename = 'wt';
const pagetxt = 'TV 시청';

const MainTv = () => {
  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);
  const { tvLastData } = useContext(TvLastContext);

  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());

  const changeDateRange = (sd, ed) => {
    setDateRange({ start: sd, end: ed })
  }

  return (
    <div className="wrap wrap-mobile">
      <div className="layout-mobile layout-sub">

        <div className="wrap-wt">

          <MainDetailHeader page={page} pagetxt={pagetxt} state={state} safety={safety} rangeSaveAction={changeDateRange} />

          <ActivitySectCurrent pagename={pagename} subpagename="tv" onoffText={{on : "켜짐", off : "꺼짐"}} sensorLastData={tvLastData} />

          <section className="wt-sect sect-chart">
            <div className="wt-chart-top">
              <h3 className="sect-tit wt-chart-tit">기간별 {pagetxt} 추이</h3>
              <a href="#!" className="wt-chart-btn-date" onClick={() => openModalRange()}>기간설정</a>
            </div>
            <div className="wt-chart-cont">
              <ActivitySectChartDate pagename="wt" url="/api/v1/user/activity/tv/range" sensorLastData={tvLastData} dateRange={dateRange} color="#48847D"/>
            </div>
          </section>

          <Bottom pagename={pagename} />

        </div>
      </div>
    </div>
  );
}

export default MainTv;
