import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosInterceptors from "../../utils/axios-interceptors";

import { StateContext, SafetyContext, OsLastContext } from "../../components/provider/main-provider";

import MainHealthHeader, { openModalRange, openModalRecord } from "../../components/header/main/main-health-header";
import OsHelpModal from "../../components/modal/os-help-modal";
import OsSectCurrent from "../../components/health/os-sect-current";
import HealthSectChart from "../../components/health/health-sect-chart";
import Bottom from "../../components/bottom";

import { successAlert, errorAlert } from "../../utils/alert";

import { getWeekOnlyYMD, getDateToIsoformat, getDateToYMDHHMMOrMDHHMM } from "../../utils/date";
import { makeEchartOption } from "../../components/echart-graph";

const page = 'sub';
const pagename = 'os';
const pagetxt = '산소포화도';

const dateParser = (date) => {
  const dateObj = new Date(date);
  return getDateToYMDHHMMOrMDHHMM(dateObj);
}

const valueConverter = (value) => value.toFixed(0);

const MainOxy = () => {
  const navigate = useNavigate();

  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);

  const { osData, refresh } = useContext(OsLastContext);

  const [helpActive, setHelpActive] = useState('');

  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());
  const [optionOsLineDate, setOptionOsLineDate] = useState(null);

  useEffect(() => {
    getAxiosInterceptors(navigate)
      .get(
        '/api/v1/user/health/oxy/range',
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end) } }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setOptionOsLineDate(
            makeEchartOption(
              data.map((value) => dateParser(value.date)),
              [{
                data: data.map((value) => valueConverter(value.spo2)),
                color: '#4372E0',
              }]
            )
          );
        }
      });
  }, [dateRange, osData]);

  const changeDateRange = (sd, ed) => {
    setDateRange({ start: new Date(sd), end: new Date(ed) })
  }

  const recordSave = (value, date) => {
    if (value.input1) {
      getAxiosInterceptors(navigate)
        .post(
          '/api/v1/user/health/oxy/add',
          {
            spo2: value.input1,
            bpm: -1,
            date: date
          }
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200) {
            successAlert("산소포화도가 정상적으로 저장되었습니다.");
            refresh();
          }
        })
        .catch(error => {
          errorAlert("저장에 실패했습니다.");
        });
    }
  }

  return (
    <div className="wrap wrap-mobile">
      <div className="layout-mobile layout-sub">

        <div className="wrap-os">

          <MainHealthHeader page={page} pagename={pagename} pagetxt={pagetxt} state={state} safety={safety} rangeSaveAction={changeDateRange} recordSaveAction={recordSave} />

          <OsHelpModal helpOsActive={helpActive} setHelpOsActive={setHelpActive} />

          <OsSectCurrent osData={osData} setHelpOsActive={setHelpActive} />

          <HealthSectChart pagename="os" pagetxt={pagetxt} openModalRange={openModalRange} dateRange={dateRange} echartOption={optionOsLineDate} />

          <Bottom pagename={pagename} writeOnClick={() => openModalRecord("oxygen-saturation")} />

        </div>

      </div>
    </div>
  );
}

export default MainOxy;
