import React, {useContext, useState } from "react";
import Logout from "../../components/logout";

import { StateContext, SafetyContext, DashboardCheckContext } from "../../components/provider/main-provider";

import MainHeader from "../../components/header/main/main-header";
import Bottom from "../../components/bottom";

const stateArr = ['건강', '조심', '주의', '위험', '응급']

const MainCheck = () => {
  const [page] = useState('sub');
  const pagename = 'together';
  const pagetxt = '마을건강';

  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);

  const { loading, dashboardCheckData } = useContext(DashboardCheckContext);

  return (
    <div className="wrap wrap-mobile">
      <div className="layout-mobile layout-sub">

        <div className="wrap-together">

          <MainHeader page={page} pagetxt={pagetxt} state={state} safety={safety} />

          <section className="together-sect sect-visual">
            <div className="together-visual">
              <div className="together-visual-img">
                <img src="/assets/images/pages/together/bg-together.png" alt="마을건강 함께" />
              </div>
            </div>
          </section>

          <section className="together-sect sect-people">
            <div className="together-people">
              <ul className="together-people-list-head">
                <li className="together-people-col together-people-col-name">이름</li>
                <li className="together-people-col together-people-col-bp">
                  <div className="together-people-mark-bp">

                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="그룹_18516" data-name="그룹 18516" transform="translate(-281 -261)">
                        <rect id="사각형_1003" data-name="사각형 1003" width="43" height="24" rx="12" transform="translate(281 261)" />
                        <text id="혈압" transform="translate(302 277)" fill="#fff" fontSize="13" fontFamily="SUIT-Medium, SUIT" fontWeight="500" letterSpacing="-0.05em"><tspan x="-11" y="0">혈압</tspan></text>
                        <path id="패스_4348" data-name="패스 4348" d="M7,12.078,14.25,5H7Z" transform="translate(291.875 279)" />
                      </g>
                    </svg>
                    <img src="/assets/images/pages/dashboard/icon-blood-pressure-yellow.svg" alt="혈압" />

                  </div>
                </li>
                <li className="together-people-col together-people-col-bs">
                  <div className="together-people-mark-bs">

                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="그룹_18986" data-name="그룹 18986" transform="translate(-281 -261)">
                        <rect id="사각형_1003" data-name="사각형 1003" width="43" height="24" rx="12" transform="translate(281 261)" />
                        <text id="혈당" transform="translate(302 277)" fill="#fff" fontSize="13" fontFamily="SUIT-Medium, SUIT" fontWeight="500" letterSpacing="-0.05em"><tspan x="-11" y="0">혈당</tspan></text>
                        <path id="패스_4348" data-name="패스 4348" d="M7,12.078,14.25,5H7Z" transform="translate(291.875 279)" />
                      </g>
                    </svg>
                    <img src="/assets/images/pages/dashboard/icon-blood-sugar-pink.svg" alt="혈당" />

                  </div>
                </li>
                <li className="together-people-col together-people-col-os">
                  <div className="together-people-mark-os">

                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="그룹_18987" data-name="그룹 18987" transform="translate(-280.866 -261)">
                        <rect id="사각형_1003" data-name="사각형 1003" width="76" height="24" rx="12" transform="translate(280.866 261)" />
                        <text id="산소포화도" transform="translate(318 277)" fill="#fff" fontSize="13" fontFamily="SUIT-Medium, SUIT" fontWeight="500" letterSpacing="-0.05em"><tspan x="-27" y="0">산소포화도</tspan></text>
                        <path id="패스_4348" data-name="패스 4348" d="M7,12.078,14.25,5H7Z" transform="translate(308.866 279)" />
                      </g>
                    </svg>
                    <img src="/assets/images/pages/dashboard/icon-oxygen-saturation-blue.svg" alt="산소포화도" />

                  </div>
                </li>
                <li className="together-people-col together-people-col-result">건강지표</li>
              </ul>

              <ul className="together-people-list">
                {dashboardCheckData.length === 0 && loading &&
                  (<li className="together-people-li">
                    <ul className="together-people-list-person">
                      <li className="together-people-col together-people-col-name">loading...</li>
                    </ul>
                  </li>)}
                {
                  dashboardCheckData.map(item => {
                    return (
                      <li className="together-people-li" key={item.user_id}>
                        <ul className="together-people-list-person">
                          <li className="together-people-col together-people-col-name">{item.name}</li>
                          <li className="together-people-col together-people-col-bp">
                            <div className={`together-people-check ${item.bp ? "active" : ""}`}></div>
                          </li>
                          <li className="together-people-col together-people-col-bs">
                            <div className={`together-people-check ${item.bs ? "active" : ""}`}></div>
                          </li>
                          <li className="together-people-col together-people-col-os">
                            <div className={`together-people-check ${item.ox ? "active" : ""}`}></div>
                          </li>
                          <li className="together-people-col together-people-col-result">
                            <div className={`together-people-tag ${item.state}`}>
                              <div className="icon"></div>
                              <div className="txt">{item.score != null && item.score < 5 && item.score >= 0 ? stateArr[item.score] : item.state}</div>
                            </div>
                          </li>
                        </ul>
                      </li>
                    )
                  })
                }

                <li className="together-people-li">
                  <button type="button" className="btn btn-logout"><Logout /></button>
                </li>

              </ul>
            </div>
          </section>

          <Bottom pagename={pagename} />

        </div>

      </div>
    </div>

  )
}
export default MainCheck