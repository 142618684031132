import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosInterceptors from "../../utils/axios-interceptors";
import { getWeekOnlyYMD, getDateToIsoformat, getDateToYMDHHMMOrMDHHMM } from "../../utils/date";

import { StateContext, SafetyContext, BsLastContext } from "../../components/provider/main-provider";

import MainHealthHeader, { openModalRange, openModalRecord } from "../../components/header/main/main-health-header";
import BsHelpModal from "../../components/modal/bs-help-modal";
import BsSectCurrent from "../../components/health/bs-sect-current";
import HealthSectChart from "../../components/health/health-sect-chart";
import Bottom from "../../components/bottom";

import { successAlert, errorAlert } from "../../utils/alert";
import { makeEchartOption } from "../../components/echart-graph";

const page = 'sub';
const pagename = 'bs';
const pagetxt = '혈당';

const dateParser = (date) => {
  const dateObj = new Date(date);
  return getDateToYMDHHMMOrMDHHMM(dateObj);
}

const valueConverter = (value) => value.toFixed(1);

const MainBS = () => {
  const navigate = useNavigate();
  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);

  const { bsData, refresh } = useContext(BsLastContext);

  const [helpActive, setHelpActive] = useState('');

  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());
  const [optionBsLineDate, setOptionBsLineDate] = useState(null);

  useEffect(() => {
    getAxiosInterceptors(navigate)
      .get(
        '/api/v1/user/health/bs/range',
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end) } }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setOptionBsLineDate(
            makeEchartOption(
              data.map((value) => dateParser(value.date)),
              [{
                name: "mg/dl",
                data: data.map((value) => valueConverter(value.bld_sugar)),
                color: '#F42F7E',
              }],
            )
          );
        }
      });
  }, [dateRange, bsData]);

  const changeDateRange = (sd, ed) => {
    setDateRange({ start: sd, end: ed })
  }

  const recordSave = (value, date) => {
    if (value.input1) {
      getAxiosInterceptors(navigate)
        .post(
          '/api/v1/user/health/bs/add',
          {
            bloodSugar: value.input1,
            date: date,
          }
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200) {
            successAlert("혈당 데이터가 정상적으로 등록 되었습니다.");
            refresh();
          }
        })
        .catch(error => {
          errorAlert("저장에 실패했습니다.");
        });
    }
  }

  return (
    <div className="wrap wrap-mobile">
      <div className="layout-mobile layout-sub">

        <div className="wrap-bs">

          <MainHealthHeader page={page} pagename={pagename} pagetxt={pagetxt} state={state} safety={safety} rangeSaveAction={changeDateRange} recordSaveAction={recordSave} />

          <BsHelpModal helpBsActive={helpActive} setHelpBsActive={setHelpActive} />

          <BsSectCurrent bsData={bsData} setHelpBsActive={setHelpActive} />

          <HealthSectChart pagename="bs" pagetxt={pagetxt} openModalRange={openModalRange} dateRange={dateRange} echartOption={optionBsLineDate} />

          <Bottom pagename={pagename} writeOnClick={() => openModalRecord("blood-sugar")} />

        </div>

      </div>
    </div>
  );
}

export default MainBS;
