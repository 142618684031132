import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import getAxiosInterceptors from "../../utils/axios-interceptors";
import Table from '../../components/table';
// import '../../style/common.css';
// import '../../style/input.css';
// import '../../style/scroll.css';

const DeviceNew = ({ pendingList = null }) => {
  const navigate = useNavigate();

  const columnData = [
    {
      accessor: "device_id",
      Header: "센서 ID",
    },
    // {
    //   accessor: "gateway_id",
    //   Header: "게이트웨이 ID",
    // },
    {
      accessor: "device_type",
      Header: "센서 종류",
    },
    {
      accessor: "install_location",
      Header: "설치 장소",
      Cell: ({ row }) => (
        <input
          type="text"
          name="install_location"
          placeholder="위치(장소)"
          onChange={(e) => handleChange(e, row.index)}
        />
      ),
    },
    // {
    //   accessor: "reg_date",
    //   Header: "등록 일시",
    // },
    {
      accessor: "insert",
      Header: "",
      Cell: ({ row }) => (
        <button
          onClick={(event) => {
            event.preventDefault();
            handleSubmit(row.original)
          }}
        >
          추가
        </button>
      )
    }
  ];

  const [info, setInfo] = useState([]);
  const columns = useMemo(() => columnData, []);

  useEffect(() => {
    const items = [];
    for (let value of pendingList) {
      const item = {
        "device_id": value[0],
        "gateway_id": value[1],
        "device_type": value[2],
        "install_location": "",
        "reg_date": value[3],
      };
      items.push(item);
    }
    setInfo(items);
    console.log("미등록 장치 info:", items)
  }, []);

  const handleSubmit = useCallback((rowData) => {
    console.log(rowData)
    // if (rowData.install_location) {
      getAxiosInterceptors(navigate)
        .post(
          '/api/v1/user/device/new', rowData
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200)
            alert(response.data.msg);
          navigate(0)
        })
        .catch(error => {
          console.error('Error:', error);
        });
    // } else {
    //   alert("기기 정보를 입력해주세요.");
    // }
  }, [info]);


  const handleChange = useCallback((event, index) => {
    const { name, value } = event.target;
    setInfo(prevInfo => {
      const newData = [...prevInfo];
      newData[index][name] = value;
      return newData;
    });
  }, []);


  return (
    <div class="wrap wrap-mobile">
      <div class="layout-mobile layout-device">
        <form>
          <Table columns={columns} data={info} handleChange={handleChange} handleSubmit={handleSubmit} />
        </form>
      </div>
    </div>
  );
}
export default DeviceNew;