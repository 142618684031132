import React from "react"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SosModal from "../../modal/sos-modal";

const healthStates = [
  { en: 'good', ko: '건강' },
  { en: 'careful', ko: '조심' },
  { en: 'warning', ko: '주의' },
  { en: 'danger', ko: '위험' },
  { en: 'emergency', ko: '응급' }
];

/**
 * 
 * @param {{
 * page : string, 
 * pagename : string | undefined, 
 * pagetxt : string, 
 * state : string, 
 * safety : string, 
 * rangeSaveAction : ((start_date : string, end_date : string) => void) | undefined, 
 * recordSaveAction : ((value : {input1:number, input2:number|undefined}, date : string) => void) | undefined
 * }} param0 
 * @returns 
 */
export const MainHeader = ({ page, pagetxt, state = 'good', safety = 'normal' }) => {
  let resultState = healthStates.find(({ en }) => en === state);
  const navigate = useNavigate();

  return (
    <>
      <SosModal />
      <header className={`header header-${page}`}>
        <Link to='/main' className={`logo header-logo logo-header-${page}`}>
          <div className="icon"></div>
          <div className="txt">
            <img src="/assets/images/common/logo-txt-white.svg" alt="독거가구 응급안전 안심 서비스" />
          </div>
        </Link>
        <div className={`pagename header-pagename-${page}`}>{pagetxt}</div>
        <button type="button" className={`btn-result btn-result-header-${page} ${state}`} onClick={() => navigate('/main')}>
          <div className="icon"></div>
          <div className="txt">{resultState.ko}</div>
        </button>
        {safety != null &&
          <button type="button" className={`btn-safety btn-safety-header-${page} ${safety}`} onClick={() => navigate('/main/safe')}>
            <div className="icon"></div>
            <div className="txt">{safety === "normal" ? "정상" : "위험"}</div>
          </button>
        }
      </header>
    </>
  )
};

export default MainHeader;