import React, { useContext, useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";

import { DashboardMngrContext } from "../../components/provider/mngr-provider";
import { getYMDDateArray } from "../../utils/date";

import MngrHeader from "../../components/header/mngr/mngr-header"
import EchartLineGraph, { makeEchartOption } from "../../components/echart-graph";

const page = 'mngr';
const pagename = 'mngrdash';
const pagetxt = "마을 주민 건강 정보";

const stateArr = ['건강', '조심', '주의', '위험', '응급']

const Mngr = () => {
  const { loading, dashboardMngrData } = useContext(DashboardMngrContext);
  const [filterdDashboardMngrData, setFilterdDashboardMngrData] = useState([])

  useEffect(() => setFilterdDashboardMngrData(dashboardMngrData), [dashboardMngrData]);

  return (
    <>
      <div className={`wrap wrap-desktop`}>
        <div className={`layout-desktop layout-${page}`}>
          <div className={`wrap-${pagename}`}>

            <MngrHeader pagetxt={pagetxt} />

            <section className={`${pagename}-sect sect-search`}>
              <MngrSearchBar loading={loading} dashboardMngrData={dashboardMngrData} setFilterdDashboardMngrData={setFilterdDashboardMngrData} />
            </section>

            <section className={`${pagename}-sect sect-people`}>
              <div className={`${pagename}-people`}>
                <ul className={`${pagename}-people-list-head`}>
                  <li className={`${pagename}-people-col ${pagename}-people-col-health`}>건강지표</li>
                  <li className={`${pagename}-people-col ${pagename}-people-col-safety`}>안전화재</li>
                  <li className={`${pagename}-people-col ${pagename}-people-col-name`}>이름</li>
                  <li className={`${pagename}-people-col ${pagename}-people-col-activities`}>활동</li>
                  <li className={`${pagename}-people-col ${pagename}-people-col-more`}>상세</li>
                </ul>

                <ul className={`${pagename}-people-list`}>
                  {dashboardMngrData.length === 0 && loading &&
                    (<li className="together-people-li">
                      <ul className="together-people-list-person">
                        <li className="together-people-col together-people-col-name">loading...</li>
                      </ul>
                    </li>)
                  }
                  <MngrPeopleList filterdData={filterdDashboardMngrData} />
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

const MngrSearchBar = memo(({ loading, dashboardMngrData, setFilterdDashboardMngrData }) => {
  const [searchStr, setSearchStr] = useState('');
  const searchChangeHandle = (event) => {
    setSearchStr(event.target.value)
  };

  const searchKeyDownHandle = (event) => {
    if (event.key === 'Escape') {
      setSearchStr('');
      setFilterdDashboardMngrData(dashboardMngrData);
    }

    if (event.key === 'Enter' && !loading) {
      if (searchStr)
        setFilterdDashboardMngrData(
          dashboardMngrData.filter(item => {
            const stateStr = item.state + item.score + stateArr[item.score];
            const safetyStr = item.safety != null ? item.safety ? "위험" : "안전" : '-';
            const str = (stateStr + safetyStr + item.name).toLowerCase();
            return str.includes(searchStr.toLowerCase());
          })
        )
      else setFilterdDashboardMngrData(dashboardMngrData);
    }
  };

  return (
    <label className={`search-wrap`}>
      <input name="search" type="text" className={`search`} placeholder="검색어를 입력하세요" value={searchStr}
        onChange={searchChangeHandle} onKeyDown={searchKeyDownHandle} />
      <button className={`btn-search`} onClick={() => searchKeyDownHandle({ key: "Enter" })}>
        <img src="assets/images/mngr/btn-search-black.svg" alt="검색" />
      </button>
    </label>
  );
});

const GRAPH_OPTION_BY_TYPE = {
  'motion': {
    'name':'행동',
    'color':'#92ACEB'
  },
  'door': {
    'name':'문열림',
    'color':'#8667C4'
  },
  'plug': {
    'name':'TV 시청',
    'color':'#48847D'
  },
}

const MngrPeopleList = memo(({ filterdData }) => {
  const { graphLoading, dashboardMngrGraphData } = useContext(DashboardMngrContext);

  const isData = (user_id) => {
    return dashboardMngrGraphData.data && (Object.keys(Reflect.get(dashboardMngrGraphData.data, user_id) || {}).length > 0)
  }

  const make = (user_id) => {
    const range = dashboardMngrGraphData.range;
    const xData = getYMDDateArray(new Date(range.start), new Date(range.end));

    const data = dashboardMngrGraphData.data;
    const device = Reflect.get(data, user_id)
    const device_data = Object.values(device)

    const yData = device_data.map((obj) => {
      return {
        name: obj.loc + " " + GRAPH_OPTION_BY_TYPE[obj.type]['name'],
        data: obj.data.map((obj) => obj.hour),
        color: GRAPH_OPTION_BY_TYPE[obj.type]['color']
      }
    });
    return makeEchartOption(xData, yData, (data) => `${data}시간`, { min: -7, max: 24 });
  }

  return filterdData.map(item => {
    const data = {
      koState: stateArr[item.score],
      ...item
    }
    return (
      <li className={`${pagename}-people-li`} key={item.user_id}>
        <ul className={`${pagename}-people-list-person`}>
          <li className={`${pagename}-people-col ${pagename}-people-col-health`}>
            <div className={`${pagename}-people-tag ${item.state}`}>
              <div className={`icon`}></div>
              <div className={`txt`}>{stateArr[item.score]}</div>
            </div>
          </li>
          <li className={`${pagename}-people-col ${pagename}-people-col-safety ${item.safety != null ? item.safety ? "danger" : "normal" : 'nodata'}`}>{item.safety != null ? item.safety ? "위험" : "안전" : '-'}</li>
          <li className={`${pagename}-people-col ${pagename}-people-col-name`}>{item.name}</li>
          <li className={`${pagename}-people-col ${pagename}-people-col-activities`}>
            {
              graphLoading && !dashboardMngrGraphData.data ? "불러오는 중..." : (
                isData(item.user_id) ?
                  (
                    <EchartLineGraph id={`${pagename}-echart-line-${item.user_id}`} className={`echart-line echart-line-mngr`}
                      echartOption={make(item.user_id)} />
                  )
                  : "-")
            }
          </li>
          <li className={`${pagename}-people-col ${pagename}-people-col-more`}>
            <Link to={`/mngr/health`} state={data} className={`${pagename}-btn-info btn-info-health`}>건강정보</Link>
            <Link to={`/mngr/activity`} state={data} className={`${pagename}-btn-info btn-info-activities`}>활동정보</Link>
            {item.safety != null && <Link to={`/mngr/safety`} state={data} className={`${pagename}-btn-info btn-info-safety`}>안전정보</Link>}
          </li>
        </ul>
      </li>
    )
  })
});

export default Mngr;
