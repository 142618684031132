const global = {
    auth : '',
    name: '',
}

const getAuth = () => global.auth;
const setAuth = (num) => global.auth = num;

const getName = () => global.name;
const setName = (name) => global.name = name;

export default global;
export {getAuth, setAuth, getName, setName};