import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import getAxiosInterceptors from "../../utils/axios-interceptors";

import Swiper from "swiper";

import { getWeekOnlyYMD, getDateToYMDHHMMOrMDHHMM, getDateToIsoformat } from "../../utils/date";
import { successAlert, errorAlert } from "../../utils/alert";

import { MngrHealthHeader, openModalRange, openModalRecord } from "../../components/header/mngr/mngr-health-header";
import { MngrContext, BpLastContext, BsLastContext, OsLastContext } from "../../components/provider/mngr-provider";
import { BpHelpModal, BsHelpModal, OsHelpModal } from "../../components/modal";
import { BpSectCurrent, BsSectCurrent, OsSectCurrent, HealthSectChart } from "../../components/health";
import Bottom from "../../components/bottom";

import { makeEchartOption } from "../../components/echart-graph";

const page = 'mngr';
const pagename = 'mngrhealth';
const pagetxt = '건강정보'

const dateParser = (date) => {
  const dateObj = new Date(date);
  return getDateToYMDHHMMOrMDHHMM(dateObj);
};

const valueConverter = (value) => value.toFixed(0);

const MngrHealth = () => {
  const navigate = useNavigate();
  const { data } = useContext(MngrContext);

  const { bpData, refresh: bpRfresh } = useContext(BpLastContext);
  const { bsData, refresh: bsRefresh } = useContext(BsLastContext);
  const { osData, refresh: oxyRefresh } = useContext(OsLastContext);

  const [tabActive, setTabActive] = useState('bp');

  const [helpBpActive, setHelpBpActive] = useState('');
  const [helpBsActive, setHelpBsActive] = useState('');
  const [helpOsActive, setHelpOsActive] = useState('');

  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());
  const [optionBpLineDate, setOptionBpLineDate] = useState(null);
  const [optionBsLineDate, setOptionBsLineDate] = useState(null);
  const [optionOsLineDate, setOptionOsLineDate] = useState(null);

  const [swiper, setSwiper] = useState(null);

  const bpWriteOnClick = useCallback(() => openModalRecord("blood-pressure"), []);
  const bsWriteOnClick = useCallback(() => openModalRecord("blood-sugar"), []);
  const oxWriteOnClick = useCallback(() => openModalRecord("oxygen-saturation"), []);

  const changeDateRange = useCallback((sd, ed) => {
    setDateRange({ start: sd, end: ed })
  }, []);

  const bpRecordSave = useCallback((value, date) => {
    if (value.input1 && value.input2) {
      getAxiosInterceptors(navigate)
        .post(
          `/api/v1/mngr/health/bp/add?user=${data.user_id}`,
          {
            systolic: value.input1,
            diastolic: value.input2,
            date: date
          }
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200) {
            successAlert("혈압 데이터가 정상적으로 등록되었습니다.");
            bpRfresh();
          }
        })
        .catch(error => {
          errorAlert("저장에 실패했습니다.");
        });
    }
  }, [])

  const bsRecordSave = useCallback((value, date) => {
    if (value.input1) {
      getAxiosInterceptors(navigate)
        .post(
          `/api/v1/mngr/health/bs/add?user=${data.user_id}`,
          {
            bloodSugar: value.input1,
            date: date,
          }
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200) {
            successAlert("혈당 데이터가 정상적으로 등록 되었습니다.");
            bsRefresh();
          }
        })
        .catch(error => {
          errorAlert("저장에 실패했습니다.");
        });
    }
  }, []);

  const osRecordSave = useCallback((value, date) => {
    if (value.input1) {
      getAxiosInterceptors(navigate)
        .post(
          `/api/v1/mngr/health/oxy/add?user=${data.user_id}`,
          {
            spo2: value.input1,
            bpm: -1,
            date: date
          }
        )
        .then((response) => {
          const status_code = response.status;
          if (status_code === 200) {
            successAlert("산소포화도가 정상적으로 저장되었습니다.");
            oxyRefresh();
          }
        })
        .catch(error => {
          errorAlert("저장에 실패했습니다.");
        });
    }
  }, []);

  const recordSave = useCallback((value, date) => {
    if (tabActive === "bp")
      bpRecordSave(value, date);
    else if (tabActive === "bs")
      bsRecordSave(value, date);
    else if (tabActive === "os")
      osRecordSave(value, date);
  }, [tabActive])

  useEffect(() => {
    const swiperMngr = new Swiper('.swiper.swiper-mngrhealth', {
      slidesPerView: "auto",
      spaceBetween: 60,
      preventClicks: false,
      allowTouchMove: false,
    });

    setSwiper(swiperMngr);

    return () => {
      swiperMngr.destroy(true, true);
      setSwiper(null);
    };
  }, []);

  useEffect(() => {
    if (!(data.user_id && data.name)) {
      navigate('/mngr');
      return;
    }

    getAxiosInterceptors(navigate)
      .get(
        '/api/v1/mngr/health/bp/range',
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end), user: data.user_id } }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setOptionBpLineDate(
            makeEchartOption(data.map((value) => dateParser(value.date)),
              [{
                name: "sys",
                data: data.map((value) => valueConverter(value.systolic)),
                color: '#F4BA2F'
              },
              {
                name: "dia",
                data: data.map((value) => valueConverter(value.diastolic)),
                color: '#C98A2A',
              }])
          );
        }
      });

    getAxiosInterceptors(navigate)
      .get(
        '/api/v1/mngr/health/bs/range',
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end), user: data.user_id } }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setOptionBsLineDate(
            makeEchartOption(
              data.map((value) => dateParser(value.date)),
              [{
                name: "mg/dl",
                data: data.map((value) => valueConverter(value.bld_sugar)),
                color: '#F42F7E',
              }],
            )
          );
        }
      });

    getAxiosInterceptors(navigate)
      .get(
        '/api/v1/mngr/health/oxy/range',
        { params: { start: getDateToIsoformat(dateRange.start), end: getDateToIsoformat(dateRange.end), user: data.user_id } }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setOptionOsLineDate(
            makeEchartOption(
              data.map((value) => dateParser(value.date)),
              [{
                data: data.map((value) => valueConverter(value.spo2)),
                color: '#4372E0',
              }]
            )
          );
        }
      });
  }, [dateRange, bpData, bsData, osData]);

  return (
    <div className={`wrap wrap-desktop`}>
      <div className={`layout-desktop layout-${page}`}>

        <div className={`wrap-${pagename}`}>

          <MngrHealthHeader page={page} pagename={tabActive} pagetxt={pagetxt + " - " + data.name} rangeSaveAction={changeDateRange} recordSaveAction={recordSave} />

          <BpHelpModal helpBpActive={helpBpActive} setHelpBpActive={setHelpBpActive} />
          <BsHelpModal helpBsActive={helpBsActive} setHelpBsActive={setHelpBsActive} />
          <OsHelpModal helpOsActive={helpOsActive} setHelpOsActive={setHelpOsActive} />

          <div className={`${pagename}-tabs`}>
            <button type="button" className={`${pagename}-tab bp ${tabActive === "bp" ? "active" : ''}`} onClick={() => { setTabActive('bp'); swiper.slideTo(0, 1000) }}>혈압</button>
            <button type="button" className={`${pagename}-tab bs ${tabActive === "bs" ? "active" : ''}`} onClick={() => { setTabActive('bs'); swiper.slideTo(1, 1000) }}>혈당</button>
            <button type="button" className={`${pagename}-tab os ${tabActive === "os" ? "active" : ''}`} onClick={() => { setTabActive('os'); swiper.slideTo(2, 1000) }}>산소포화도</button>
          </div>
          <div className={`${pagename}-containers swiper swiper-${pagename}`}>
            <div className={`swiper-wrapper`}>
              <div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "bp" ? "active" : ""}`} onClick={() => { setTabActive('bp'); swiper.slideTo(0, 1000) }}>
                <div className={`${pagename}-container ${pagename}-container-bp`}>

                  <BpSectCurrent bpData={bpData} setHelpBpActive={setHelpBpActive} />

                  <HealthSectChart pagename="bp" pagetxt="혈압" openModalRange={openModalRange} dateRange={dateRange} echartOption={optionBpLineDate} />

                  <Bottom pagename="bp" isMngr={true} writeOnClick={bpWriteOnClick} />
                </div>
              </div>

              <div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "bs" ? "active" : ""}`} onClick={() => { setTabActive('bs'); swiper.slideTo(1, 1000) }}>
                <div className={`${pagename}-container ${pagename}-container-bs`}>

                  <BsSectCurrent bsData={bsData} setHelpBsActive={setHelpBsActive} />

                  <HealthSectChart pagename="bs" pagetxt="혈당" openModalRange={openModalRange} dateRange={dateRange} echartOption={optionBsLineDate} />

                  <Bottom pagename="bs" isMngr={true} writeOnClick={bsWriteOnClick} />

                </div>
              </div>

              <div className={`swiper-slide swiper-${pagename}-slide ${tabActive === "os" ? "active" : ""}`} onClick={() => { setTabActive('os'); swiper.slideTo(2, 1000) }}>
                <div className={`${pagename}-container ${pagename}-container-os`}>

                  <OsSectCurrent osData={osData} setHelpOsActive={setHelpOsActive} />

                  <HealthSectChart pagename="os" pagetxt="산소포화도" openModalRange={openModalRange} dateRange={dateRange} echartOption={optionOsLineDate} />

                  <Bottom pagename="os" isMngr={true} writeOnClick={oxWriteOnClick} />

                </div>
              </div>

            </div>

          </div>

          <Bottom pagename={pagename} isMngr={true} isWriteBtnShow={false} />
        </div>

      </div>
    </div>
  )
}

export default MngrHealth;