import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';

const tvLastUrl = {
  true: '/api/v1/mngr/activity/tv/last',
  false:'/api/v1/user/activity/tv/last',
}

export const TvLastContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean, userId : number | null}} param0 
 * @returns 
 */
export const TvLastProvider = ({ children, allow_url, isMngr = false, userId = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tvLastData, setTvLastData] = useState({});

  useEffect(() => {
    if(isMngr && !userId){
      return;
    }
    
    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      const requset = () => {
        getAxiosInterceptors(navigate)
        .get(tvLastUrl[isMngr], isMngr && userId != null ? {params : {user : userId}} : undefined)
        .then((response) => {
          if (response.data.msg === "success") {
            setTvLastData(response.data.data);
          }
        });
      }

      requset();
      const interval = setInterval(() =>{
        requset();
      }, 50000);

      return () => clearInterval(interval);
    }

  }, [location.pathname]);

  return (
    <TvLastContext.Provider value={{ tvLastData, setTvLastData }}>
      {children}
    </TvLastContext.Provider>
  )
}