import React, { useState, useContext, useEffect } from "react";
import { getWeekOnlyYMD, getTodayOnlyYMD } from "../../utils/date";

import { StateContext, SafetyContext, DashboardMainContext } from "../../components/provider/main-provider";
import MainDetailHeader, { openModalRange } from "../../components/header/main/main-detail-header";
import { RtSwiper } from "../../components/activity";
import Bottom from "../../components/bottom";

const page = 'sub';
const pagename = 'rt';
const pagetxt = '온습도';

const MainTemp = () => {
  const { state } = useContext(StateContext);
  const { safety } = useContext(SafetyContext);

  const { tempData, humiData } = useContext(DashboardMainContext);

  const [temp, setTemp] = useState(0);
  const [humi, setHumi] = useState(0);

  const [tab, setTab] = useState('temp');
  const [calTempState, setCalTempState] = useState('time');
  const [calHumiState, setCalHumiState] = useState('time');
  const [rangeMode, setRangeMode] = useState('single'); // single

  const [dateSingle, setDateSingle] = useState(getTodayOnlyYMD());
  const [dateRange, setDateRange] = useState(getWeekOnlyYMD());

  useEffect(() => {
    if (tempData && tempData.value)
      setTemp(parseInt(tempData.value));
    if (humiData && humiData.value)
      setHumi(parseInt(humiData.value));
  }, [tempData, humiData]);

  const changeDateRange = (sd, ed) => {
    if ((tab === 'temp' && calTempState === 'time') || (tab === 'humi' && calHumiState === 'time'))
      setDateSingle(sd);
    else if ((tab === 'temp' && calTempState === 'date') || (tab === 'humi' && calHumiState === 'date'))
      setDateRange({ start: sd, end: ed });
  }

  return (
    <div className="wrap wrap-mobile">
      <div className={`layout-mobile layout-${page}`}>
        <div className={`wrap-${pagename}`}>

          <MainDetailHeader page={page} mode={rangeMode} pagetxt={pagetxt} state={state} safety={safety} rangeSaveAction={changeDateRange} />

          <RtSwiper setRangeMode={setRangeMode} openModalRange={openModalRange}
            temp={temp} humi={humi}
            dateSingle={dateSingle} setDateSingle={setDateSingle}
            dateRange={dateRange} setDateRange={setDateRange}
            tab={tab} setTab={setTab}
            calTempState={calTempState} setCalTempState={setCalTempState}
            calHumiState={calHumiState} setCalHumiState={setCalHumiState} />

          <Bottom pagename={pagename} />

        </div>

      </div>
    </div>
  );
}

export default MainTemp;
