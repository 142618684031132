import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { StateContext, SafetyContext } from "../../components/provider/main-provider";
import { SafetyCurrentBox } from "../../components/safety";

import MainHeader from "../../components/header/main/main-header";
import Bottom from "../../components/bottom";

const MainSafe = () => {
  const navigate = useNavigate();

  const [page] = useState('sub');
  const pagename = 'safety';
  const pagetxt = '안전';

  const { state } = useContext(StateContext);
  const { safety, safetyData } = useContext(SafetyContext);

  useEffect(() => {
    if(safety === null)
      navigate('/main');
  }, [safety]);

  return (
    <div className="wrap wrap-mobile">
      <div className="layout-mobile layout-sub">

        <div className="wrap-safety">
          <MainHeader page={page} pagetxt={pagetxt} state={state} safety={safety} />

          <section className="safety-sect sect-current">
            <div className="safety-current-top">
              <h3 className="sect-tit safety-current-tit">{pagetxt} 센서 상태</h3>
            </div>
            <div className="safety-current-cont">
              <div className="safety-current-boxes">
                <SafetyCurrentBox subname={{en : "fire", ko : "화재"}} safetyData={safetyData}/>

                <SafetyCurrentBox subname={{en : "gas", ko : "가스"}} safetyData={safetyData}/>
                
                <SafetyCurrentBox subname={{en : "smoke", ko : "연기"}} safetyData={safetyData}/>
              </div>
            </div>
          </section>

          <Bottom pagename={pagename} writeOnClick={() => navigate('/main/api/v1/user/health/bp/input')} />

        </div>

      </div>
    </div>
  );
}

export default MainSafe;
