import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';

export const DashboardMngrContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>}} param0 
 * @returns 
 */
export const DashboardMngrProvider = ({ children, allow_url }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const apiCtr = useRef(new AbortController());

  const [loading, setLoading] = useState(false);
  const [graphLoading, setGraphLoading] = useState(false);
  const [dashboardMngrData, setDashboardMngrData] = useState([]);
  const [dashboardMngrGraphData, setDashboardMngrGraphData] = useState({});

  useEffect(() => {
    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      const requset = () => {
        setLoading(true);
        setGraphLoading(true);

        getAxiosInterceptors(navigate)
          .get('/api/v1/mngr/main', { params: { offset: new Date().getTimezoneOffset() }, signal: apiCtr.current.signal })
          .then((response) => {
            setLoading(false);
            setDashboardMngrData(response.data || []);
          })
          .catch((err) => { setLoading(false); });

        getAxiosInterceptors(navigate)
          .get('/api/v1/mngr/activity/overall', { signal: apiCtr.current.signal })
          .then((response) => {
            setGraphLoading(false);
            setDashboardMngrGraphData(response.data || {});
          })
          .catch((err) => { setGraphLoading(false); });
      }

      requset();
      const interval = setInterval(() => {
        requset();
      }, 300000);

      return () => {
        clearInterval(interval);
        apiCtr.current.abort();
        apiCtr.current = new AbortController();
      };
    }
  }, [location.pathname]);

  return (
    <DashboardMngrContext.Provider value={{ loading, graphLoading, dashboardMngrData, setDashboardMngrData, dashboardMngrGraphData, setDashboardMngrGraphData }}>
      {children}
    </DashboardMngrContext.Provider>
  )
}