import React, { useMemo, memo } from 'react';
import { useLocation } from "react-router-dom";
import { DashboardMngrContext, DashboardMngrProvider } from './dashboard-mngr-provider';
import { BpLastContext, BpLastProvider } from './bp-provider';
import { BsLastContext, BsLastProvider } from './bs-provider';
import { OsLastContext, OsLastProvider } from './oxy-provider';
import { TvLastContext, TvLastProvider } from './tv-last-provider';
import { MotionLastContext, MotionLastProvider } from './motion-last-provider';
import { DoorLastContext, DoorLastProvider } from './door-last-provider';
import { DashboardMainContext, DashboardMainProvider } from './dashboard-main-provider';
import { SafetyContext, SafetyProvider } from './safety-provider';

export const MngrContext = React.createContext(null);

const _MngrProvider = ({ children }) => {
  const location = useLocation();
  const data = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  return (
    <MngrContext.Provider value={{ data }}>
      <DashboardMngrProvider allow_url={["/mngr"]}>
        <BpLastProvider allow_url={["/mngr/health"]} isMngr={true} userId={data.user_id}>
          <BsLastProvider allow_url={["/mngr/health"]} isMngr={true} userId={data.user_id}>
            <OsLastProvider allow_url={["/mngr/health"]} isMngr={true} userId={data.user_id}>
              <TvLastProvider allow_url={["/mngr/activity"]} isMngr={true} userId={data.user_id}>
                <MotionLastProvider allow_url={["/mngr/activity"]} isMngr={true} userId={data.user_id}>
                  <DoorLastProvider allow_url={["/mngr/activity"]} isMngr={true} userId={data.user_id}>
                    <DashboardMainProvider allow_url={["/mngr/activity"]} isMngr={true} userId={data.user_id}>
                      <SafetyProvider allow_url={["/mngr/safety"]} isMngr={true} userId={data.user_id}>
                        {children}
                      </SafetyProvider>
                    </DashboardMainProvider>
                  </DoorLastProvider>
                </MotionLastProvider>
              </TvLastProvider>
            </OsLastProvider>
          </BsLastProvider>
        </BpLastProvider>
      </DashboardMngrProvider>
    </MngrContext.Provider>
  );
};
export const MngrProvider = memo(_MngrProvider);

export { DashboardMngrContext, BpLastContext, BsLastContext, OsLastContext, TvLastContext, MotionLastContext, DoorLastContext, DashboardMainContext, SafetyContext }