import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import getAxiosInterceptors from '../../utils/axios-interceptors';
import { getDateToYMDOrMD } from '../../utils/date';

const oxyLastUrl = {
  true: '/api/v1/mngr/health/oxy/last',
  false: '/api/v1/user/health/oxy/last',
}

export const OsLastContext = React.createContext(null);

/**
 * 
 * @param {{children : React.JSX.Element, allow_url : Array<string>, isMngr : boolean, userId : number | null}} param0 
 * @returns 
 */
export const OsLastProvider = ({ children, allow_url, isMngr = false, userId = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [osData, setOsData] = useState(null);
  const [flag, setFlag] = useState(false);
  const refresh = () => setFlag(!flag);

  useEffect(() => {
    if(isMngr && !userId){
      return;
    }

    if (!allow_url || (allow_url && allow_url.includes(location.pathname))) {
      getAxiosInterceptors(navigate)
        .get(oxyLastUrl[isMngr], isMngr && userId != null ? {params : {user : userId}} : undefined)
        .then((response) => {
          if (response.data.msg === 'success') {
            const value = response.data.data;

            const threeMonthAgo = new Date();
            threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
            const time = new Date(value.time);

            const data = {
              time: getDateToYMDOrMD(time),
              disabled: time < threeMonthAgo,
              spo2: value.spo2,
              bpm: value.bpm,
              figure: value.figure ? value.figure === "정상" ? "정상" : value.figure === "저산소증주의" ? "주의" : value.figure === "측정오류" ? "오류" : "위험" : "",
              detail_figure: value.figure,
            }
            setOsData(data);
          }
          else setOsData({ msg: "NO DATA" });
        });
    }

  }, [location.pathname, flag]);

  return (
    <OsLastContext.Provider value={{ osData, setOsData, refresh }}>
      {children}
    </OsLastContext.Provider>
  )
}