const operators = {
  '+': { precedence: 1, func: (a, b) => a + b },
  '-': { precedence: 1, func: (a, b) => a - b },
  '*': { precedence: 2, func: (a, b) => a * b },
  '/': { precedence: 2, func: (a, b) => a / b },
};

export const calculate = (expression) => {
  const stack = [];
  const outputQueue = [];
  const tokens = expression.match(/-\d+(\.\d+)?|\d+(\.\d+)?|\+|-|\*|\/|\(|\)/g);

  tokens.forEach(token => {
    const floatVal = parseFloat(token);
    if (isNaN(floatVal)) {
      while (stack.length > 0 && operators[stack[stack.length - 1]].precedence >= operators[token].precedence) {
        outputQueue.push(stack.pop());
      }
      stack.push(token);
    } else {
      outputQueue.push(floatVal);
    }
  });

  while (stack.length > 0) {
    outputQueue.push(stack.pop());
  }

  let calculationStack = [];
  outputQueue.forEach(token => {
    if (typeof token === 'number') {
      calculationStack.push(token);
    } else {
      const b = calculationStack.pop();
      const a = calculationStack.pop();
      calculationStack.push(operators[token].func(a, b));
    }
  });
  
  return calculationStack[0];
}

export default calculate;