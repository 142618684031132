import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setAuth, getAuth, setName } from '../../utils/global';
import { useNavigate } from "react-router";

/**
 * @param {{children:React.JSX.Element, isAdmin:boolean}} param0 
 * @returns 
 */
export const PrivateRoute = ({ children, isAdmin }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!axios.defaults.headers.common.Authorization) {
      axios
        .get('/api/v1/auth/refresh')
        .then((response) => {
          if (response.statusText === 'OK') {
            const token = response.data.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setAuth(response.data.auth);
            setName(response.data.name)
          }
          else {
            axios.defaults.headers.common['Authorization'] = '';
            navigate('/login');
            setAuth('');
            setName('');
          }
        })
        .catch((error) => {
          axios.defaults.headers.common['Authorization'] = '';
          navigate('/login');
          setAuth('');
          setName('');
        })
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, []);

  if (loading) return <div>Loading...</div>;

  if (isAdmin && getAuth() === "1") navigate('/main');
  else if (!isAdmin && getAuth() === "0") navigate('/mngr');

  return <>{children}</>;
}