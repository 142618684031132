import React, { useEffect } from 'react';
import axios from 'axios';
import { setAuth, getAuth, setName } from '../../utils/global';
import { useNavigate } from "react-router";

/**
 * @param {{children : React.JSX.Element}} param0 
 * @returns 
 */
export const AutoDashBoardRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {

    if (axios.defaults.headers.common.Authorization && getAuth() in ["0", "1"]) {
      if (getAuth === "1")
        navigate('/main');
      else if (getAuth() === "0")
        navigate('/mngr');

    }
    else {
      axios
        .get('/api/v1/auth/refresh')
        .then((response) => {
          if (response.statusText === 'OK') {
            const token = response.data.token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setAuth(response.data.auth);
            setName(response.data.name);
            if (getAuth() === "1")
              navigate('/main');
            else if (getAuth() === "0")
              navigate('/mngr');
          }
        })
    }
  }, []);

  return <>{children}</>;
}